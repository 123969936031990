import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { formatNumber } from "app/utils/formatNumber";
import { PageHeader } from "app/components/page-header";
import { NetworkChart } from "app/components/charts/network";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { ExploreListAllBlock } from "app/components/explore-list-all-block";
import { ExploreListBlockItem } from "app/components/explore-list-block-Item";
import { SECTOR_COLUMNS as cols } from "app/pages/explorer/pages/sectors/tableColumns";
import { ReactComponent as ExplorerOrganisationsHeaderIcon } from "app/assets/vectors/ExplorerOrganisationsHeaderIcon.svg";

const box = <Box height={40} />;

export const ExplorerSectors = () => {
  const [activeOption, setActiveOption] = React.useState("");

  const stats = useStoreState((state) =>
    get(state.ExplorerSectorStats, "data.data", {
      disbursement: 0,
      sdgActivities: 0,
      countries: [],
    })
  );
  const statsLoading = useStoreState(
    (state) => state.ExplorerSectorStats.loading
  );
  const fetchStats = useStoreActions(
    (actions) => actions.ExplorerSectorStats.fetch
  );
  const list = useStoreState((state) =>
    get(state.ExplorerSectorsList, "data.data", {
      dac3: [],
      dac5: [],
    })
  );
  const listLoading = useStoreState(
    (state) => state.ExplorerSectorsList.loading
  );
  const fetchList = useStoreActions(
    (actions) => actions.ExplorerSectorsList.fetch
  );
  const partnersNetwork = useStoreState((state) =>
    get(state.ExplorerSectorPartners, "data.data", {
      data: {
        nodes: [],
        links: [],
        categories: [],
      },
      count: 0,
    })
  );
  const fetchPartnersNetwork = useStoreActions(
    (actions) => actions.ExplorerSectorPartners.fetch
  );

  const SECTORS = React.useMemo(() => {
    let items =
      activeOption === "DAC 3 Digit Sectors"
        ? get(list, "dac3", [])
        : get(list, "dac5", []);

    return items.map((item: any) => ({
      id: item.code,
      locations: item.locations,
      incomingFunds: item.incomingFunds
        ? formatNumber(item.incomingFunds, true)
        : "-",
      activities: item.activities,
      organisations: item.organisations,
      sector: item.name,
      name: item.name,
    }));
  }, [activeOption, list]);

  const SECTOR_COLUMNS = React.useMemo(() => {
    const r = cols;
    r[5].headerName = "Sector highlights reflect 2023 data.";
    return r;
  }, []);

  React.useEffect(() => {
    fetchStats({
      routeParams: {
        year: "2023",
        code: "31161",
        transactionTypeCode: "1",
      },
    });
    fetchList({
      filterString:
        "startDate=2023-01-01T00:00:01Z&endDate=2023-12-31T23:59:59Z",
    });
    fetchPartnersNetwork({
      routeParams: {
        code: "31161",
      },
    });
  }, []);

  return (
    <Box>
      <PageHeader
        variant="explorer"
        title="Faces Behind Impact: Explore Organizations"
        subtitle="Get ready to dive into the heart of humanitarian aid with AIDA's organisation-specific data. This feature invites you to explore a network of dedicated organizations making a real difference in the world."
        icon={<ExplorerOrganisationsHeaderIcon />}
      />
      {box}
      <Typography variant="h2" fontSize={"36px"} lineHeight={"normal"}>
        Explore the Sector of the Month
      </Typography>
      {box}
      <ExploreListBlockItem
        title="SECTOR OF THE MONTH"
        description="Food crop production"
        loading={statsLoading}
        leftItems={[
          {
            title: "Overview",
            description: (
              <React.Fragment>
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {formatNumber(stats.disbursement, true)}
                </span>{" "}
                was spent for
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {" "}
                  {stats.countries.length} recipient countries{" "}
                </span>{" "}
                in 2023.
              </React.Fragment>
            ),
          },
          {
            title: "Locations",
            description: (
              <React.Fragment>
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {stats.countries
                    .slice(0, 4)
                    .map((c: any) => c.name)
                    .join(", ")}
                </span>{" "}
                were the largest recipient countries of 2023.
              </React.Fragment>
            ),
          },
          {
            title: "Results",
            description: (
              <React.Fragment>
                In 2023, available results show activities surpassed about{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  4.8%
                </span>{" "}
                of their target results on average.
              </React.Fragment>
            ),
          },
          {
            title: "SUSTAINABLE DEVELOPMENT GOALS",
            description: (
              <React.Fragment>
                In 2023,{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {stats.sdgActivities} activities{" "}
                </span>
                were contributing to the SDGs.
              </React.Fragment>
            ),
          },
          {
            title: "FUNDING Analysis",
            description: (
              <React.Fragment>
                Funding for Food crop production sector has been
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {" "}
                  increased 1.4%{" "}
                </span>
                in 2023 compared to 2022 data.
              </React.Fragment>
            ),
          },
          {
            title: "ORGANISATIONS",
            description: (
              <React.Fragment>
                Top 10 organisations provides in total of
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {" "}
                  678,2 million{" "}
                </span>
                development aid in 2023. Which makes up the{" "}
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {" "}
                  85% of the total development aid.
                </span>
              </React.Fragment>
            ),
          },
        ]}
        rightContent={{
          title: "NETWORKS",
          description: (
            <React.Fragment>
              <span
                style={{
                  color: colors.primary.blue,
                }}
              >
                {partnersNetwork.count} organisations
              </span>{" "}
              have published data on their development and humanitarian
              resources and activities.
            </React.Fragment>
          ),
          content: <NetworkChart height="319px" data={partnersNetwork.data} />,
        }}
        buttonText="See the Sector Page"
        buttonLink="/explorer/sectors/31161"
      />
      {box}
      <Typography variant="h2" fontSize={"36px"} lineHeight={"normal"}>
        Explore All Sectors & Search
      </Typography>
      {box}
      <ExploreListAllBlock
        rows={SECTORS}
        loading={listLoading}
        columns={SECTOR_COLUMNS}
        activeExtraOption={activeOption}
        setActiveExtraOption={setActiveOption}
        extraOptions={["DAC 3 Digit Sectors", "DAC 5 Digit Sectors"]}
      />
    </Box>
  );
};
