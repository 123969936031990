import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Table } from "app/components/table";
import Typography from "@mui/material/Typography";
import { PageHeader } from "app/components/page-header";
import { NetworkChart } from "app/components/charts/network";
import { useParams, useSearchParams } from "react-router-dom";
import { useExpandedBlock } from "app/hooks/useExpandedBlock";
import SearchBigIcon from "app/assets/vectors/jsx/SearchBigIcon";
import { ChartPlaceholder } from "app/components/chart-placeholder";
import { OverviewBlock } from "app/components/grid-blocks/overview";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { ExpandedResultsBlock } from "./components/expanded-results-block";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { RELATED_ACTIVITY_COLUMNS } from "app/pages/explorer/pages/activities/pages/activity/tableData";
import { DescriptionBlock } from "app/pages/explorer/pages/activities/pages/activity/components/description-block";
import { TransactionsBlock } from "app/pages/explorer/pages/activities/pages/activity/components/transactions-block";
import { ReactComponent as ExplorerActivitiesHeaderIcon } from "app/assets/vectors/ExplorerActivitiesHeaderIcon.svg";

export const ExplorerActivity: React.FC = () => {
  const { id } = useParams();
  const [currentQueryParameters, setSearchParams] = useSearchParams();

  const fetchActivity = useStoreActions(
    (actions) => actions.ExplorerActivityPage.fetch
  );
  const activity = useStoreState((state) =>
    get(state.ExplorerActivityPage, "data.data", {
      title: "",
      description: [
        {
          lang: "",
          text: "",
        },
      ],
      reportingOrg: "",
      status: "",
      scope: "",
      dates: {
        startActual: "",
        startPlanned: "",
        endActual: "",
        endPlanned: "",
      },
      contact: {
        type: "",
        org: "",
        telephone: "",
        email: "",
        website: "",
        department: "",
        jobTitle: "",
        mailingAddress: "",
      },
    })
  );
  const fetchTransactions = useStoreActions(
    (actions) => actions.ExplorerActivityPageTransactions.fetch
  );
  const fetchTransactionsLineChart = useStoreActions(
    (actions) => actions.ExplorerActivityPageTransactionsLineChart.fetch
  );
  const fetchRelatedActivities = useStoreActions(
    (actions) => actions.ExplorerActivityPageRelatedActivities.fetch
  );
  const relatedActivities = useStoreState((state) =>
    get(state.ExplorerActivityPageRelatedActivities, "data.data", [])
  );
  const relatedActivitiesLoading = useStoreState(
    (state) => state.ExplorerActivityPageRelatedActivities.loading
  );
  const fetchOrganisations = useStoreActions(
    (actions) => actions.ExplorerActivityPageOrganisations.fetch
  );
  const fetchResults = useStoreActions(
    (actions) => actions.ExplorerActivityPageResults.fetch
  );

  const { expandedBlock, handleSetExpandedBlock } = useExpandedBlock({
    blockIds: ["results", "networks"],
  });

  const getBlockHeight = React.useCallback((blockId: string) => {
    const el = document.getElementById(blockId);
    return el ? `${el.offsetHeight}px` : "100%";
  }, []);

  const networksData: {
    nodes: {
      id: string;
      name: string;
      symbolSize: number;
      category: number;
    }[];
    links: {
      source: string;
      target: string;
    }[];
    categories: {
      name: string;
    }[];
  } = useStoreState((state) =>
    get(state.ExplorerActivityPageOrganisations.data, "data", {
      nodes: [],
      links: [],
      categories: [],
    })
  );
  const networksLoading = useStoreState(
    (state) => state.ExplorerActivityPageOrganisations.loading
  );
  const networks = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="Networks"
        loading={networksLoading}
        onExpand={() => handleSetExpandedBlock("networks")}
        height="100%"
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {networksData.nodes.length} organisations
            </span>{" "}
            are collaborating on this activity.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize="12px" lineHeight="normal">
              See the organisation network by their organisation type:
            </Typography>
            <NetworkChart
              data={networksData}
              showZoomWidget={expandedBlock === "networks"}
              height={expandedBlock === "networks" ? "330px" : "190px"}
            />
          </React.Fragment>
        }
        expanded={expandedBlock === "networks"}
      />
    );
  }, [expandedBlock, networksData, networksLoading]);

  const resultData = useStoreState((state) =>
    get(state.ExplorerActivityPageResults, "data.data", {
      output: [],
      outcome: [],
      impact: [],
    })
  );
  const resultsLoading = useStoreState(
    (state) => state.ExplorerActivityPageResults.loading
  );
  const results = React.useMemo(() => {
    const items = [
      { name: "Output(s)", value: resultData.output.length },
      { name: "Outcome", value: resultData.outcome.length },
      { name: "Impact", value: resultData.impact.length },
    ];

    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        loading={resultsLoading}
        title="Activity Results"
        onExpand={() => handleSetExpandedBlock("results")}
        height="100%"
        description={
          <React.Fragment>
            There's{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {resultData.output.length +
                resultData.outcome.length +
                resultData.impact.length}{" "}
              results
            </span>{" "}
            related to this activity.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            {expandedBlock === "results" ? (
              <ExpandedResultsBlock data={resultData} />
            ) : (
              <React.Fragment>
                <OverviewBlock items={items} />
                <Box height={20} />
                <Box
                  sx={{
                    padding: "15px",
                    background: colors.secondary.lightGrey,
                    display: "flex",
                    columnGap: "10px",
                    "> svg": {
                      flexShrink: 0,
                    },
                  }}
                >
                  <SearchBigIcon />
                  <Box>
                    <Typography
                      color="#000"
                      fontSize="14px"
                      fontWeight="700"
                      lineHeight="20px"
                      marginBottom="5px"
                    >
                      Expand to see detailed results.
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize="12px"
                      color="#373D43"
                      lineHeight="normal"
                    >
                      Results of the activity includes; output(s), outcome and
                      impact. Every indicator has it's on description and
                      measures. Expand to see the activity's outputs, outcome
                      and impact.
                    </Typography>
                  </Box>
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
        }
        expanded={expandedBlock === "results"}
      />
    );
  }, [expandedBlock, resultData]);

  const expandedBlockView = React.useMemo(() => {
    let view = <React.Fragment />;
    switch (expandedBlock) {
      case "results":
        view = results;
        break;
      case "networks":
        view = networks;
        break;
      default:
        break;
    }

    return (
      <React.Fragment>
        <Box height={40} />
        {view}
      </React.Fragment>
    );
  }, [results, networks]);

  const resultsBlockHeight = React.useMemo(
    () => getBlockHeight("block-Activity Results"),
    [results]
  );
  const networksBlockHeight = React.useMemo(
    () => getBlockHeight("block-Networks"),
    [networks]
  );

  React.useEffect(() => {
    if (id) {
      fetchActivity({ routeParams: { id } });
      fetchTransactions({ routeParams: { id } });
      fetchTransactionsLineChart({ routeParams: { id } });
      fetchRelatedActivities({ routeParams: { id } });
      fetchOrganisations({ routeParams: { id } });
      fetchResults({ routeParams: { id } });
    }
  }, [id]);

  return (
    <Box>
      <PageHeader
        variant="explorer"
        title={activity.title}
        icon={<ExplorerActivitiesHeaderIcon />}
      />
      <Box height={30} />
      <Grid container spacing="30px">
        {expandedBlock && (
          <Grid item xs={12}>
            {expandedBlockView}
          </Grid>
        )}
        <Grid item xs={12}>
          <DescriptionBlock />
        </Grid>
        <Grid item xs={12}>
          <TransactionsBlock />
        </Grid>
        <Grid item xs={12} md={6}>
          {expandedBlock !== "networks" ? (
            networks
          ) : (
            <ChartPlaceholder height={networksBlockHeight} />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {expandedBlock !== "results" ? (
            results
          ) : (
            <ChartPlaceholder height={resultsBlockHeight} />
          )}
        </Grid>
        <Grid item xs={12}>
          <GenericPageBlockItem
            toolbarShare
            title="Related Activities"
            loading={relatedActivitiesLoading}
            description={
              <React.Fragment>
                There's currently{" "}
                <span style={{ color: colors.primary.blue }}>
                  {relatedActivities.length} activities
                </span>{" "}
                related to this activity.
              </React.Fragment>
            }
            content={
              <React.Fragment>
                <Table
                  withSearch
                  headerFontSize="12px"
                  rows={relatedActivities}
                  columns={RELATED_ACTIVITY_COLUMNS}
                />
              </React.Fragment>
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};
