import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { PageHeader } from "app/components/page-header";
import { useStoreActions } from "app/state/store/hooks";
import { ReactComponent as LogOutIcon } from "app/assets/vectors/HeaderSignInViewLogOut.svg";
import { ReactComponent as AccountProfileHeaderIcon } from "app/assets/vectors/AccountProfileHeaderIcon.svg";
import { ReactComponent as AccountProfileDeleteIcon } from "app/assets/vectors/AccountProfileDeleteIcon.svg";

export const AccountProfileLayout: React.FC<{
  children: React.ReactNode;
}> = ({ children }: { children: React.ReactNode }) => {
  const { logout } = useAuth0();

  const clearStoredUser = useStoreActions((actions) => actions.user.clear);

  const handleLogOutButtonClick = () => {
    clearStoredUser();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <Box>
      <PageHeader
        variant="explorer"
        title="Account Profile"
        subtitle="Sign up, manage your account settings and get notifications on your activity alerts."
        icon={<AccountProfileHeaderIcon />}
      />
      <Box height={40} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "8px",
          "> button": {
            gap: "10px",
            height: "32px",
            display: "flex",
            fontSize: "12px",
            fontWeight: "700",
            borderRadius: "5px",
            alignItems: "center",
            textTransform: "none",
            justifyContent: "center",
            color: colors.primary.white,
            background: colors.secondary.red,
            ":hover": {
              background: colors.secondary.darkRed,
            },
            "> .MuiButton-icon": {
              margin: 0,
            },
          },
        }}
      >
        <Button startIcon={<LogOutIcon />} onClick={handleLogOutButtonClick}>
          Log Out
        </Button>
        <Button startIcon={<AccountProfileDeleteIcon />}>Delete Account</Button>
      </Box>
      <Box height={40} />
      {children}
    </Box>
  );
};
