import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { PageLoader } from "app/components/page-loader";

export const AuthenticatedRoute: React.FC<{
  children: React.ReactNode | React.ReactNode[];
}> = (props) => {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading } = useAuth0();

  React.useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate("/login", { replace: true });
    }
  }, [isAuthenticated, isLoading]);

  return (
    <React.Fragment>
      {isLoading && <PageLoader />}
      {!isLoading && isAuthenticated && props.children}
    </React.Fragment>
  );
};
