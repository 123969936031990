import { Box, Button, TextField, Typography } from "@mui/material";
import { useSubmitContactForm } from "app/hooks/useSubmitContactForm";
import { colors } from "app/theme";
import { emailValidation } from "app/utils/emailValidation";
import { LinearProgress } from "app/components/linear-progress";
import { get } from "lodash";
import React from "react";

export const ContactUs = () => {
  const [data, setData] = React.useState({
    email: "",
    firstName: "",
    lastName: "",
    company: "",
    message: "",
  });

  const [errors, setErrors] = React.useState<any>({});

  const { submit, loading, message } = useSubmitContactForm({
    onSuccess: () => {
      setData({
        email: "",
        firstName: "",
        lastName: "",
        company: "",
        message: "",
      });
    },
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let error = false;
    const newErrors = { ...errors };
    Object.keys(data).forEach((key) => {
      if (!get(data, key)) {
        newErrors[key] = true;
        error = true;
      } else {
        newErrors[key] = false;
      }
    });
    if (!emailValidation(data.email)) {
      newErrors.emailError = "Please enter a valid email address";
      newErrors.email = true;
      error = true;
    } else {
      newErrors.emailError = "";
    }
    if (error) {
      setErrors(newErrors);
      return;
    }
    await submit(data);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "email") {
      setErrors({ ...errors, emailError: false, email: false });
    } else {
      setErrors({ ...errors, [e.target.name]: false });
    }

    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: "30px",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <Typography fontSize={"36px"} variant="h2" lineHeight={"normal"}>
          Contact Us
        </Typography>

        <Typography
          variant="h4"
          fontSize={"18px"}
          fontWeight={"400"}
          lineHeight={"24px"}
        >
          If you have any questions about AIDA, The OpenData Company or any
          other requests, please contact us using this form.
        </Typography>
        <Typography
          variant="h4"
          fontSize={"18px"}
          fontWeight={"400"}
          lineHeight={"24px"}
        >
          Tel: +31 20 503 9091
          <br />
          info@aida.tools
        </Typography>
        <Typography
          variant="h4"
          fontSize={"18px"}
          fontWeight={"400"}
          lineHeight={"24px"}
        >
          The Open Data Company B.V.
          <br />
          Herengracht 449A,
          <br />
          1017 BR Amsterdam
          <br />
          The Netherlands
        </Typography>
      </Box>
      <Box flex={1}>
        {loading ? (
          <Box
            width="100%"
            display="flex"
            height="200px"
            alignItems="center"
            justifyContent="center"
          >
            <Box width="40%">
              <LinearProgress />
            </Box>
          </Box>
        ) : null}
        {!loading && message ? (
          <Typography variant="h6" fontSize="14px" marginBottom={"20px"}>
            {message}
          </Typography>
        ) : null}
        {!loading ? (
          <Box
            sx={{
              "> textarea": {
                resize: "none",
                height: "109px",
              },
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              ".MuiFormLabel-root": {
                fontSize: "12px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: colors.secondary.iconGray,
                  borderWidth: "1px",
                  "> legend": {
                    fontSize: "9px",
                  },
                },
                "&:hover fieldset": {
                  borderColor: colors.primary.blue,
                },
                "&.Mui-focused fieldset": {
                  borderColor: colors.primary.blue,
                },
              },
              ".MuiInputBase-root": {
                borderRadius: "5px",
                padding: "0 0",
              },
              ".MuiInputBase-input": {
                padding: "10px 20px",
                fontSize: "12px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                // borderStyle: "none",
              },
            }}
            component={"form"}
            onSubmit={handleSubmit}
            noValidate
          >
            <TextField
              variant="outlined"
              label="Email"
              required
              type="email"
              fullWidth
              name="email"
              helperText={
                errors?.email
                  ? errors?.emailError ?? "Your Email is required"
                  : ""
              }
              error={errors?.email}
              onChange={handleChange}
              value={data.email}
              size="small"
            />
            <TextField
              variant="outlined"
              label="First Name"
              required
              fullWidth
              name="firstName"
              helperText={
                errors?.firstName ? "Your First Name is required" : ""
              }
              error={errors?.firstName}
              onChange={handleChange}
              value={data.firstName}
              size="small"
            />
            <TextField
              variant="outlined"
              label="Last Name"
              required
              fullWidth
              name="lastName"
              helperText={errors?.lastName ? "Your Last Name is required" : ""}
              error={errors?.lastName}
              onChange={handleChange}
              value={data.lastName}
              size="small"
            />
            <TextField
              variant="outlined"
              label="Company Name"
              required
              fullWidth
              name="company"
              helperText={
                errors?.company ? "Your Company Name is required" : ""
              }
              error={errors?.company}
              onChange={handleChange}
              value={data.company}
              size="small"
            />
            <TextField
              variant="outlined"
              label="Message"
              multiline
              fullWidth
              rows={4}
              name="message"
              required
              helperText={errors?.message ? "Your Message is required" : ""}
              error={errors?.message}
              onChange={handleChange}
              value={data.message}
            />
            <Button
              variant="contained"
              type="submit"
              sx={{
                width: "max-content",
                lineHeight: "normal",
                display: "block",
                marginLeft: "auto",
              }}
            >
              Send Message
            </Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
