import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { PageHeader } from "app/components/page-header";
import { LeftBlock } from "app/pages/login/components/left-block";
import { ReactComponent as LaptopCat } from "app/assets/vectors/LaptopCat.svg";
import { ReactComponent as AccountProfileHeaderIcon } from "app/assets/vectors/AccountProfileHeaderIcon.svg";

const box = <Box height={40} />;

export const Login: React.FC = () => {
  return (
    <Box>
      <PageHeader
        variant="explorer"
        title="Account Profile"
        icon={<AccountProfileHeaderIcon />}
        subtitle="Sign up, manage your account settings and get notifications on your activity alerts."
      />
      {box}
      <Typography variant="h2" fontSize="36px" lineHeight="normal">
        Sign In
      </Typography>
      {box}
      <Grid container columnSpacing="40px">
        <Grid item xs={12} md={6}>
          <LeftBlock />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              padding: "30px 50px",
              background: "#ffffff",
            }}
          >
            <Typography variant="h3" fontSize="24px" lineHeight="normal">
              Sign in to AIDA to benefit from;
            </Typography>
            <Typography
              variant="body1"
              fontSize="14px"
              marginTop="10px"
              lineHeight="20px"
            >
              3-step publishing process that makes publishing development aid
              data easier than ever, and bookmarking the selected activities and
              projects to follow the latest updates.
            </Typography>
            <Box height={20} />
            <Box
              sx={{
                margin: "0 auto",
                width: "max-content",
              }}
            >
              <LaptopCat />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
