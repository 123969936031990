import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { PlanCard } from "./components/card";
import { Features } from "./components/features";
import Typography from "@mui/material/Typography";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { InfoIcon } from "app/assets/vectors/jsx/InfoIcon";
import CheckYesIcon from "app/assets/vectors/jsx/CheckYesIcon";
import { CheckGoodIcon } from "app/assets/vectors/jsx/CheckGoodIcon";

const pricingOptions = [
  {
    title: "Free Publisher",
    price: "Free",
    description:
      "The perfect package to get you started with publishing your IATI data.",
    currency: "",
    duration: "",
    recommended: false,
    buttonText: "Start Publishing",
  },
  {
    title: "Standard",
    price: "1800",
    description: "The perfect package to get you started in your IATI journey.",
    currency: "€",
    duration: "year",
    recommended: true,
    buttonText: "Start Free Trial",
  },
  {
    title: "Premium",
    price: "4200",
    description: "The perfect package to get you started in your IATI journey.",
    currency: "€",
    duration: "year",
    recommended: false,
    buttonText: "Start Free Trial",
  },
];

const planSummary = [
  "Explore + (SSO)",
  "Geomap Data View",
  "Activity Detail Pages",
  "Data Export",
  "User Management",
  "Data Management",
];

export const Pricing: React.FC = () => {
  const [selectedPlan, setSelectedPlan] = React.useState("");

  return (
    <Box>
      <Box sx={{ display: "flex", gap: "50px", marginTop: "30px" }}>
        <Box>
          <Typography variant="h1" fontSize="40px" lineHeight="normal">
            Platform for IATI Data: Validation, Publication, Visualization
          </Typography>
          <Typography
            variant="h5"
            fontSize={"14px"}
            fontWeight={"400"}
            lineHeight={"20px"}
            marginTop={"30px"}
          >
            For Standard and Premium plans, the use of the different features
            will be enabled for a single unique IATI organisation identifier, up
            to 5 users for a minimum of 12 months.
          </Typography>
          <Typography
            variant="h5"
            fontSize={"14px"}
            fontWeight={"400"}
            lineHeight={"20px"}
            marginTop={"30px"}
          >
            Looking for a custom plan? <a href="/pricing">Contact Us.</a>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
          }}
        >
          {pricingOptions.map((option) => (
            <PlanCard
              key={option.title}
              option={option}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
            />
          ))}
        </Box>
      </Box>
      <Box height={30} />
      {selectedPlan ? (
        <Box>
          <Button
            variant="contained"
            sx={{
              lineHeight: "normal",
            }}
            startIcon={<ArrowBack />}
          >
            Go Back to Plans
          </Button>{" "}
          <Box height={30} />
          <Box
            sx={{
              backgroundColor: colors.primary.white,
              padding: "30px",
              display: "flex",
              gap: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                flex: 1,
                gap: "20px",
              }}
            >
              <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
                <Typography
                  variant="h2"
                  fontSize={"36px"}
                  lineHeight={"normal"}
                >
                  Payment successful!
                </Typography>
                <CheckYesIcon />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  width: "max-content",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    lineHeight: "normal",
                  }}
                >
                  Go to pricing page
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    lineHeight: "normal",
                  }}
                >
                  Go to my account
                </Button>
              </Box>
            </Box>

            <Box
              sx={{
                borderRadius: "5px",
                border: `1px solid ${colors.primary.blue}`,
                position: "relative",
                width: "321px",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "0",
                  right: "34px",
                  backgroundColor: "#FFC145",
                  padding: "3px 14px 3px 15px",
                  borderRadius: "0px 0px 4px 4px",
                  color: colors.text.title,
                  fontSize: "12px",
                  fontWeight: "bold",
                  lineHeight: "normal",
                }}
              >
                Most popular
              </Box>

              <Typography
                variant="h4"
                fontSize={"18px"}
                lineHeight={"normal"}
                padding={"15px"}
              >
                Standard Plan
              </Typography>
              <Box
                sx={{
                  padding: "12px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    padding: "12px",
                  }}
                >
                  <Typography
                    variant="h5"
                    lineHeight={"20px"}
                    fontSize={"14px"}
                  >
                    Plan summary
                  </Typography>
                  <Box
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {planSummary.map((summary) => (
                      <Box
                        key={summary}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <CheckGoodIcon />
                        <Typography
                          fontSize={"14px"}
                          lineHeight={"20px"}
                          color={colors.text.title}
                        >
                          {summary}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box
                  sx={{
                    padding: "12px",
                  }}
                >
                  <Typography
                    variant="h5"
                    lineHeight={"20px"}
                    fontSize={"14px"}
                  >
                    Standard Plan per Year
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        padding: "5px 10px",
                        backgroundColor: colors.shades.blue[100],
                        borderRadius: "27px",
                        fontSize: "24px",
                        span: {
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "20px",
                        },
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        lineHeight: "normal",
                        color: colors.text.title,
                        fontWeight: "bold",
                      }}
                    >
                      <span>€</span>
                      3600
                      <span>{"/ month"}</span>
                    </Box>
                    <Typography
                      variant="h5"
                      lineHeight={"20px"}
                      fontSize={"14px"}
                      fontWeight={"400"}
                    >
                      (2 Users)
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    padding: "12px",
                  }}
                >
                  <Typography
                    variant="h5"
                    lineHeight={"20px"}
                    fontSize={"14px"}
                  >
                    Standard Support
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        padding: "5px 10px",
                        backgroundColor: colors.shades.blue[100],
                        borderRadius: "27px",
                        fontSize: "24px",
                        span: {
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "20px",
                        },
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        lineHeight: "normal",
                        color: colors.text.title,
                        fontWeight: "bold",
                      }}
                    >
                      <span>€</span>
                      1750
                      <span>{"/ month"}</span>
                    </Box>
                    <InfoIcon />
                  </Box>
                </Box>
                <Box
                  sx={{
                    padding: "12px",
                  }}
                >
                  <Typography
                    variant="h5"
                    lineHeight={"20px"}
                    fontSize={"14px"}
                  >
                    Total per Year
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        padding: "5px 10px",
                        backgroundColor: colors.text.title,
                        borderRadius: "27px",
                        fontSize: "24px",
                        span: {
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "20px",
                        },
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        lineHeight: "normal",
                        color: colors.primary.white,
                        fontWeight: "bold",
                      }}
                    >
                      <span>€</span>
                      5350
                      <span>{"/ month"}</span>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Features />
      )}
    </Box>
  );
};
