import { Box, Collapse, Typography } from "@mui/material";
import { colors } from "app/theme";
import {
  CONTENT_COLUMNS,
  METADATA_COLUMNS,
  VALIDATION_REPORT_COLUMNS,
} from "../../tableData";
import { SimpleTable } from "app/components/simple-table";
import { Close } from "@mui/icons-material";
import React from "react";

interface DetailPanelContentProps {
  row: any;
  expandedRowIds: string[];
  setExpandedRowIds: React.Dispatch<React.SetStateAction<string[]>>;
}

export const DetailPanelContent = (props: DetailPanelContentProps) => {
  const showMetadata = props.expandedRowIds.includes(
    `${props.row.id}.showMetadata`
  );

  const showValidationReport = props.expandedRowIds.includes(
    `${props.row.id}.showValidationReport`
  );

  return (
    <Collapse
      in={showMetadata || showValidationReport}
      id={`detail-panel-content-${props.row.id}`}
    >
      <Box
        sx={{
          border:
            showMetadata || showValidationReport
              ? `0.5px solid ${colors.secondary.iconGray}`
              : "none",
          backgroundColor: colors.secondary.lightGrey,
          overflow: "hidden",
          padding: "20px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            top: 20,
            right: 20,
            gap: "5px",
          }}
        >
          <Typography
            variant="subtitle1"
            fontSize={"12px"}
            fontWeight={400}
            color={colors.primary.blue}
            component={"a"}
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              props.setExpandedRowIds((prev) =>
                prev.filter(
                  (id) =>
                    id !== `${props.row.id}.showMetadata` &&
                    id !== `${props.row.id}.showValidationReport`
                )
              );
            }}
          >
            Collapse
          </Typography>

          <Close
            sx={{
              width: "19px",
              height: "19px",
              color: colors.secondary.iconGray,
            }}
          />
        </Box>
        <Collapse unmountOnExit in={showMetadata}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: "20px",
            }}
          >
            <Box>
              <Typography variant="h6" fontSize={"12px"}>
                Metadata
              </Typography>
              <Box height={16} />
              <SimpleTable
                variant="vertical"
                rows={[props.row]}
                columns={METADATA_COLUMNS}
              />
            </Box>
            <Box>
              <Typography variant="h6" fontSize={"12px"}>
                Contents
              </Typography>
              <Box height={16} />
              <SimpleTable
                variant="vertical"
                rows={[props.row]}
                columns={CONTENT_COLUMNS}
              />
            </Box>
          </Box>
        </Collapse>
        {showMetadata && showValidationReport && <Box height={16} />}
        <Collapse unmountOnExit in={showValidationReport}>
          <Typography variant="h6" fontSize={"12px"}>
            Validation Report
          </Typography>
          <Box height={16} />
          <SimpleTable
            columns={VALIDATION_REPORT_COLUMNS}
            rows={[props.row.validationReport]}
            variant="horizontal"
          />
        </Collapse>
      </Box>
      <Box height={8} />
    </Collapse>
  );
};
