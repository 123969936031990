import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { InfoTooltip } from "app/components/info-tooltip";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiSwitch, { SwitchProps as MuiSwitchProps } from "@mui/material/Switch";

const IOSSwitch = styled((props: MuiSwitchProps) => (
  <MuiSwitch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 71,
  height: 32,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 4,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(38px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 24,
    height: 24,
    shadow: "2px 1px 6px rgba(0, 0, 0, 0.25)",
  },
  "& .MuiSwitch-track": {
    borderRadius: 18,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

interface SwitchProps {
  label: string;
  info?: string | React.ReactNode;
}

export const Switch: React.FC<SwitchProps> = (props: SwitchProps) => {
  const labelComp = React.useMemo(() => {
    if (!props.info) return props.label;
    return (
      <Box
        sx={{
          gap: "5px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {props.label}
        <InfoTooltip arrow mode="dark" info={props.info}>
          <InfoOutlined
            fontSize="small"
            htmlColor={colors.secondary.grayDark}
          />
        </InfoTooltip>
      </Box>
    );
  }, [props.label, props.info]);

  return (
    <FormControlLabel
      label={labelComp}
      labelPlacement="start"
      control={<IOSSwitch sx={{ m: 1 }} />}
      sx={{
        alignItems: "center",
        "& .MuiFormControlLabel-label": {
          fontSize: "12px",
        },
      }}
    />
  );
};
