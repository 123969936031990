import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import DatePicker from "react-datepicker";
import Divider from "@mui/material/Divider";
import {
  shortDayLocale,
  DateRangePickerProps,
} from "app/components/date-range-picker/data";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

export const DateRangePicker = (props: DateRangePickerProps) => {
  return (
    <Box
      sx={{
        gap: "10px",
        display: "flex",
        padding: "12px",
        borderRadius: "5px",
        width: "fit-content",
        background: colors.secondary.lightGrey,
        border: `1px solid ${colors.secondary.iconGray}`,
      }}
    >
      <Box>
        <DatePicker
          inline
          highlightDates={[]}
          selectsStart
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          locale={shortDayLocale}
          endDate={props.endDate}
          maxDate={props.endDate}
          disabledKeyboardNavigation
          selected={props.startDate}
          startDate={props.startDate}
          onChange={(date) => date && props.setStartDate(date)}
        />
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box>
        <DatePicker
          inline
          selectsEnd
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          locale={shortDayLocale}
          endDate={props.endDate}
          selected={props.endDate}
          minDate={props.startDate}
          disabledKeyboardNavigation
          startDate={props.startDate}
          onChange={(date) => date && props.setEndDate(date)}
        />
      </Box>
    </Box>
  );
};
