import React from "react";
import get from "lodash/get";
import sumBy from "lodash/sumBy";
import maxBy from "lodash/maxBy";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { BarChart } from "app/components/charts/bar";
import { PieChart } from "app/components/charts/pie";
import { SDGChart } from "app/components/charts/sdg";
import { useStoreState } from "app/state/store/hooks";
import { formatNumber } from "app/utils/formatNumber";
import { CtaCommonBtn } from "app/components/cta-common-btn";
import { NetworkChart } from "app/components/charts/network";
import { LandingPageWidget } from "app/components/landing-page-widget";
import { LandingPageBlockItem } from "app/components/landing-page-block-item";
import { ExplorerLargeCtaIcon } from "app/assets/vectors/jsx/ExplorerLargeCtaIcon";
import { PublisherLargeCtaIcon } from "app/assets/vectors/jsx/PublisherLargeCtaIcon";
import {
  SectorWidgetInfoText,
  IatiDataInNumbersProps,
} from "app/pages/home/explorer/components/iati-in-numbers/data";

export const IatiDataInNumbers: React.FC<IatiDataInNumbersProps> = (
  props: IatiDataInNumbersProps
) => {
  const SectorWidgets = (wProps: {
    items: IatiDataInNumbersProps["sectors"];
  }) => {
    const data = wProps.items.map((item) => ({
      title: item.name,
      description: "Disbursement for 2023",
      valueText: `USD ${formatNumber(item.value, true)}`,
      percentageChange: parseInt(item.changePercentage.toFixed(2), 10),
    }));

    return (
      <React.Fragment>
        <Box>
          {data.map((item, index) => (
            <React.Fragment key={item.title}>
              <LandingPageWidget
                title={item.title}
                description={item.description}
                valueText={item.valueText}
                percentageChange={item.percentageChange}
                infoText={<SectorWidgetInfoText />}
              />
              {index !== data.length - 1 && (
                <Box
                  sx={{
                    padding: "9.5px 0",
                  }}
                >
                  <Divider />
                </Box>
              )}
            </React.Fragment>
          ))}
        </Box>
        <Box height={20} />
      </React.Fragment>
    );
  };

  const orgDonutTotal = React.useMemo(
    () => sumBy(props.orgDonut, "value"),
    [props.orgDonut]
  );

  const sdgsLoading = useStoreState((state) => state.ExplorerHomeSDGs.loading);
  const budgetBySectorLoading = useStoreState(
    (state) => state.ExplorerHomeBudgetBySector.loading
  );
  const orgNetworkLoading = useStoreState(
    (state) => state.ExplorerHomeOrganisationsNetwork.loading
  );

  const topSector = React.useMemo(() => {
    const value = maxBy(
      props.budgetBySector.values,
      (value: number[]) => value[value.length - 1]
    );
    if (!value) return null;
    const index = props.budgetBySector.values.indexOf(value);
    return props.budgetBySector.series[index];
  }, [props.budgetBySector]);

  return (
    <React.Fragment>
      <Typography variant="h3" fontSize="36px">
        IATI Data, in Numbers
      </Typography>
      <Box height={20} />
      <Grid container columnSpacing="30px">
        <Grid item xs={12} md={4}>
          <LandingPageBlockItem
            content={
              <SDGChart
                labelGroup="Activities"
                data={props.sdgsData.data.slice(0, 9).map((item) => ({
                  sdg: item.code,
                  name: item.name,
                  value: item.value,
                }))}
              />
            }
            loading={sdgsLoading}
            title="Top six trending Sustainable Development Goals of 2023."
            description={
              <React.Fragment>
                In 2023,{" "}
                <span style={{ color: colors.primary.blue }}>
                  {props.sdgsData.count} activities
                </span>{" "}
                were contributing to the SDGs
              </React.Fragment>
            }
            linkText="Explore the Latest Data"
            linkUrl="/explorer"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <LandingPageBlockItem
            content={
              <BarChart
                barWidth="30"
                height="350px"
                yAxisLabelName="USD"
                xAxisLabelRotate={0}
                data={props.budgetBySector}
              />
            }
            loading={budgetBySectorLoading}
            title="Budgets presented for the year of 2023 by sector."
            description={
              <React.Fragment>
                Budget by sectors shows that{" "}
                <span style={{ color: colors.primary.blue }}>{topSector}</span>{" "}
                was the most funded sectors of 2023.
              </React.Fragment>
            }
            linkText="Explore the Latest Data"
            linkUrl="/explorer/sectors"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <LandingPageBlockItem
            content={
              <React.Fragment>
                <NetworkChart data={props.orgNetwork} height="310px" />
                <Box height={20} />
              </React.Fragment>
            }
            loading={orgNetworkLoading}
            title="Donor network present for the year 2023, separated by sectors. "
            description={
              <React.Fragment>
                <span style={{ color: colors.primary.blue }}>
                  {get(props.orgNetwork, "nodes", []).length} organisations
                </span>{" "}
                have published data on their development and humanitarian
                resources and activities.
              </React.Fragment>
            }
            linkText="See the Data"
            linkUrl="/explorer/organisations"
          />
        </Grid>
      </Grid>
      <Box height={50} />
      <Box
        sx={{
          display: "flex",
          columnGap: "20px",
        }}
      >
        <CtaCommonBtn
          variant="large"
          label="Explore Insights"
          text="Unlock the Power of Data for Impactful Fundraising. Discover valuable insights and opportunities to elevate your fundraising efforts like never before."
          link="/"
          bgIcon={<ExplorerLargeCtaIcon />}
        />
        <CtaCommonBtn
          variant="large"
          label="Publish Your Information to IATI"
          text="Join the ranks of NGOs that are already benefiting from AIDA Publication Service. Embrace transparency, engage more donors, and make a difference that truly matters."
          link="/"
          bgIcon={<PublisherLargeCtaIcon />}
          bgColor="green"
        />
      </Box>
      <Box height={50} />
      <Grid container columnSpacing="30px" columns={131}>
        <Grid item xs={131} md={40}>
          <LandingPageBlockItem
            content={<SectorWidgets items={props.sectors} />}
            title="Sectors with significant funding rate changes for 2023."
            description={
              <React.Fragment>
                <span style={{ color: colors.primary.blue }}>
                  {get(props.sectors, "[0].name", "")}
                </span>{" "}
                was the sector to have the highest percentage of growth for
                2023.
              </React.Fragment>
            }
            linkText="See the Data"
            linkUrl="/explorer/sectors"
          />
        </Grid>
        <Grid item xs={131} md={91}>
          <LandingPageBlockItem
            content={
              <React.Fragment>
                <Box height={20} />
                <PieChart
                  id="home-org-donut"
                  half
                  donut
                  data={props.orgDonut}
                  unit="USD"
                  height="500px"
                  unitType="organisations"
                  period="2023"
                />
              </React.Fragment>
            }
            title="Sectors to which resources were allocated."
            description={
              <React.Fragment>
                Top 10 organisations provides in total of{" "}
                <span style={{ color: colors.primary.blue }}>
                  {formatNumber(orgDonutTotal)} development aid
                </span>{" "}
                in 2023. Which makes up the{" "}
                <span style={{ color: colors.primary.blue }}>
                  85% of the total development aid.
                </span>
              </React.Fragment>
            }
            linkText="See the Data"
            linkUrl="/explorer/organisations"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
