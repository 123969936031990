import { Buffer } from "buffer";

export const downloadFile = (
  filename: string,
  ext: string,
  content?: string
) => {
  const buffer = Buffer.from(content ?? "", "utf-8");
  const blob = new Blob([buffer]);
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.href = url;
  a.download = `${filename}.${ext}`;
  a.click();
  window.URL.revokeObjectURL(url);
};
