import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

export const CreateBookmark: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/bookmark`),
};

export const DeleteBookmark: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/bookmark/{id}`),
};

export const GetBookmarks: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/bookmarks`),
};
