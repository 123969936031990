import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useDropzone, FileRejection } from "react-dropzone";
import { ReactComponent as CloudUploadIcon } from "app/assets/vectors/PublisherFileUploadDropzoneCloud.svg";

export interface DropzoneProps {
  inputRef: React.MutableRefObject<HTMLInputElement | undefined>;
  uploadError: boolean;
  disabled: boolean;
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
}

export const DropZone = (props: DropzoneProps) => {
  const ACCEPTED_FILES = {
    "text/csv": [".csv"],
    // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    //   ".xlsx",
    // ],
    // "application/xml": [".xml"],
    // "application/vnd.ms-excel": [".xls"],
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: true,
    onDrop: props.onDrop,
    accept: ACCEPTED_FILES,
  });

  return (
    <Box
      sx={{
        width: "100%",
        height: "284px",
        display: "flex",
        borderRadius: "10px",
        alignItems: "center",
        justifyContent: "center",
        background: !isDragActive ? "transparent" : colors.shades.green[100],
        border: `1px ${!isDragActive ? "dashed" : "solid"} ${
          !isDragActive ? colors.secondary.iconGray : colors.primary.green
        }`,
      }}
      {...getRootProps()}
    >
      <Box
        sx={{
          gap: "20px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <CloudUploadIcon />
        <Typography variant="h5" fontSize="14px" fontWeight="normal">
          Drag and drop your files here or select other upload the options
          below.
        </Typography>
      </Box>
      <input
        {...getInputProps()}
        data-testid="file-upload"
        ref={props.inputRef as React.RefObject<HTMLInputElement>}
      />
    </Box>
  );
};
