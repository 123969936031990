import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { HeaderButtonsProps } from "app/components/header/sub-components/buttons/data";
import { useAuth0 } from "@auth0/auth0-react";

export const HeaderButtons: React.FC<HeaderButtonsProps> = (
  props: HeaderButtonsProps
) => {
  const { isAuthenticated, user } = useAuth0();

  return (
    <Box
      sx={{
        gap: "5px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        "& > button": {
          height: "32px",
          fontSize: "14px",
          fontWeight: "700",
          borderRadius: "5px",
          color: colors.text.title,
          border: "1px solid transparent",
        },
      }}
    >
      {props.buttons.map((button) => {
        const buttonStyle =
          button.label === props.activeButton
            ? {
                borderColor: colors.secondary.iconGray + "!important",
                background: colors.secondary.disableButton,
              }
            : {};
        if (button.link) {
          return (
            <Button
              key={button.label}
              component={Link}
              to={button.link}
              sx={buttonStyle}
            >
              {button.label}
            </Button>
          );
        }
        let icon;
        if (button.expandable) {
          icon =
            button.label === props.activeButton ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            );
        }
        return (
          <Button
            key={button.label}
            endIcon={icon}
            onClick={() => props.handleButtonClick(button.label)}
            sx={buttonStyle}
            startIcon={
              button.signIn && isAuthenticated ? (
                <img
                  src={user?.picture}
                  width={20}
                  height={20}
                  style={{ borderRadius: "50%" }}
                />
              ) : undefined
            }
          >
            {button.signIn && isAuthenticated
              ? `Hello, ${user?.given_name}`
              : button.label}
          </Button>
        );
      })}
    </Box>
  );
};
