import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import * as echarts from "echarts/core";
import ReactDOMServer from "react-dom/server";
import { useNavigate } from "react-router-dom";
import { SVGRenderer } from "echarts/renderers";
import { TooltipComponentOption } from "echarts";
import { TooltipComponent } from "echarts/components";
import { VisualMapComponent } from "echarts/components";
import { ZoomWidget } from "app/components/zoom-widget";
import { useChartResizeObserver } from "app/hooks/useChartResizeObserver";
import { GraphSeriesOption, GraphChart as EChartsGraph } from "echarts/charts";
import {
  NetworkChartProps,
  NetworkSampleData,
} from "app/components/charts/network/data";

echarts.use([EChartsGraph, TooltipComponent, SVGRenderer, VisualMapComponent]);

const Tooltip: React.FC<any> = (props) => {
  return (
    <div
      style={{
        gap: "8px",
        width: "230px",
        lineHeight: 1.2,
        display: "flex",
        flexDirection: "column",
        color: colors.text.title,
      }}
    >
      <div
        style={{
          fontWeight: 700,
          fontSize: "12px",
          maxWidth: "200px",
          overflow: "hidden",
          whiteSpace: "wrap",
        }}
      >
        {props.data.name}
      </div>
      <div
        style={{
          gap: "4px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "20px",
            height: "13px",
            background: props.color,
          }}
        />
        <div style={{ fontSize: "12px" }}>{props.data.category}</div>
      </div>
      <div style={{ fontSize: "12px" }}>
        {props.data.value} Activit{props.data.value === 1 ? "y" : "ies"}
      </div>
    </div>
  );
};

export const NetworkChart: React.FC<NetworkChartProps> = (
  props: NetworkChartProps
) => {
  const navigate = useNavigate();
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [stateChart, setStateChart] =
    React.useState<echarts.EChartsType | null>(null);

  useChartResizeObserver({
    chart: stateChart,
    containerRef: containerRef,
    containerId: "network-chart",
  });

  function calculateRepulsion(
    numNodes: number,
    width: number,
    height: number,
    scaleFactor = 0.3
  ) {
    // Calculate the container area
    const area = width * height;

    // Estimate ideal node spacing (sqrt of area per node)
    const idealSpacing = Math.sqrt(area / numNodes);

    // Translate spacing into a repulsion force
    const repulsion = idealSpacing * scaleFactor;

    return repulsion;
  }

  const defaultZoom = props.zoom ?? 1;

  const [zoom, setZoom] = React.useState({
    zoom: defaultZoom,
    roam: props.roam ?? false,
  });

  const isEmpty = React.useMemo(() => {
    return (
      get(props.data, "nodes", []).length === 0 ||
      get(props.data, "links", []).length === 0
    );
  }, [props.data]);

  React.useEffect(() => {
    if (containerRef.current) {
      const chart = echarts.init(containerRef.current, undefined, {
        renderer: "svg",
      });

      const option: echarts.ComposeOption<
        GraphSeriesOption | TooltipComponentOption
      > = {
        color: colors.charts,
        series: [
          {
            name: "Network",
            type: "graph",
            layout: "force",
            data: !isEmpty ? props.data.nodes : NetworkSampleData.nodes,
            links: !isEmpty ? props.data.links : NetworkSampleData.links,
            color: !isEmpty ? undefined : colors.secondary.iconGray,
            categories: props.data.categories,
            zoom: zoom.zoom,
            roam: zoom.roam,
            emphasis: {
              focus: "none",
              label: {
                show: false,
              },
            },
            force: {
              repulsion: calculateRepulsion(
                (!isEmpty ? props.data.nodes : NetworkSampleData.nodes).length,
                containerRef.current.getBoundingClientRect().width,
                containerRef.current.getBoundingClientRect().height
              ),

              layoutAnimation: false,
            },
            lineStyle: {
              width: 1,
              curveness: 0.3,
              color: "source",
            },
            label: {
              show: false,
            },
          },
        ],
        tooltip: {
          show: true,
          padding: 10,
          backgroundColor: colors.secondary.lightGrey,
          borderColor: colors.secondary.lightGrayText,
          formatter: (params: any) => {
            if (params.dataType === "edge") return "";
            return ReactDOMServer.renderToString(<Tooltip {...params} />);
          },
        },
      };

      chart.on("click", (params) => {
        if (params.dataType === "node") {
          const id = get(params, "data.id", "");
          const name = get(params, "data.name", "");
          if (id && id !== name) {
            navigate(`/explorer/organisations/${id}`);
          }
        }
      });

      chart.setOption(option);
      setStateChart(chart);
    }
  }, [containerRef.current, props.data, zoom, props.height, props.roam]);

  return (
    <Box sx={{ position: "relative" }}>
      {" "}
      {props.showZoomWidget && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <ZoomWidget
            zoomProperties={zoom}
            setZoomProperties={setZoom}
            defaultZoom={defaultZoom}
          />
        </Box>
      )}
      <Box
        id="network-chart"
        ref={containerRef}
        width="100%"
        height={props.height ?? "450px"}
      />
    </Box>
  );
};
