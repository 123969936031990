import React from "react";
import { BarChartProps } from "app/components/charts/bar/data";
import { PieChartProps } from "app/components/charts/pie/data";
import { NetworkChartProps } from "app/components/charts/network/data";

export interface IatiDataInNumbersProps {
  sectors: {
    code: string;
    name: string;
    value: number;
    prevValue: number;
    changePercentage: number;
  }[];
  sdgsData: {
    count: number;
    data: {
      code: number;
      name: string;
      value: number;
    }[];
  };
  orgDonut: PieChartProps["data"];
  orgNetwork: NetworkChartProps["data"];
  budgetBySector: BarChartProps["data"];
}

export const SectorWidgetInfoText = () => (
  <React.Fragment>
    <b>What is this percentage?</b>
    <div style={{ height: 8 }} />
    Percentage variance in funding compared to the previous year (2022).
  </React.Fragment>
);
