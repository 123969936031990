import React from "react";
import { AccountProfileCard } from "app/components/account-profile-card";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Modal,
  Typography,
} from "@mui/material";
import { colors } from "app/theme";
import { SelectField } from "app/components/select-field";
import { ArrowBack, ControlPoint } from "@mui/icons-material";
import { AlertShield } from "app/assets/vectors/jsx/AlertShield";
import { ReactComponent as Astronaut } from "app/assets/vectors/Astronaut.svg";

export const AddMemberSetting = (props: {
  setActiveSetting?: (value: string) => void;
}) => {
  const [email, setEmail] = React.useState("");
  const [role, setRole] = React.useState("");
  const [errorModal, setErrorModal] = React.useState(false);

  const handleClose = () => {
    setErrorModal(false);
  };

  const accessRights = [
    { title: "Manage subscription plan", admin: true, member: false },
    { title: "Invite new team members", admin: true, member: false },
    { title: "Change organisation information", admin: true, member: false },

    { title: "Assign admin access rights", admin: true, member: false },
    { title: "Convert files on the bulk manager", admin: true, member: true },
    {
      title: "Publish files converted by bulk manager",
      admin: true,
      member: false,
    },
    { title: "Explore published data on AIDA", admin: true, member: true },
  ];

  return (
    <AccountProfileCard
      title="Organisation"
      disableSave={!email && !role}
      content={
        <React.Fragment>
          <Box>
            <Typography
              variant="h6"
              fontSize={"12px"}
              lineHeight={"normal"}
              fontWeight={"400"}
              marginBottom={"20px"}
            >
              Invite a new member to your organisation.
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "50px",
                input: {
                  width: "300px",
                  height: "32px",
                  fontSize: "12px",
                  padding: "0 20px",
                  borderRadius: "5px",
                  color: colors.text.title,
                  background: colors.secondary.lightGrey,
                  border: `1px solid ${colors.secondary.iconGray}`,
                  ":focus": {
                    outline: "none",
                  },
                },
              }}
            >
              <Box>
                <Typography
                  variant="body2"
                  lineHeight={"20px"}
                  marginBottom={"10px"}
                  component={"label"}
                  htmlFor="org-name"
                  display={"block"}
                >
                  Email
                </Typography>

                <input
                  type="text"
                  placeholder="Email*"
                  name="email"
                  id="org-name"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>

              <Box>
                <Typography
                  variant="body2"
                  lineHeight={"20px"}
                  marginBottom={"10px"}
                  component={"label"}
                  htmlFor="org-type"
                  display={"block"}
                >
                  Role
                </Typography>

                <SelectField
                  id="org-type"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  placeholder="Select a Role"
                  options={[
                    { label: "Admin", value: "Admin" },
                    {
                      label: "Member",
                      value: "Member",
                    },
                  ]}
                  width="300px"
                  variant="outlined"
                />
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
              }}
              onClick={() => setErrorModal(true)}
            >
              <ControlPoint fontSize="small" htmlColor={colors.text.title} />
              <Typography
                variant="h6"
                fontSize={"12px"}
                lineHeight={"normal"}
                fontWeight={"400"}
                sx={{
                  textDecoration: "underline",
                }}
              >
                Add more
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: "20px",
                padding: "20px 50px",
                background: colors.secondary.lightGrey,
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "19px",
                }}
              >
                <AlertShield />
                <Box>
                  <Typography
                    variant="h5"
                    fontSize={"14px"}
                    lineHeight={"20px"}
                  >
                    Understanding roles for your users
                  </Typography>
                  <Typography
                    variant="body2"
                    lineHeight={"20px"}
                    marginTop={"5px"}
                  >
                    Follow AIDA's streamlined 4-step upload process to share
                    your impact with ease.
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  marginTop: "10px",
                  padding: "20px 70px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Typography
                    variant="h5"
                    fontSize={"14px"}
                    lineHeight={"20px"}
                    width={"300px"}
                  >
                    Access rights
                  </Typography>
                  <Typography
                    variant="h5"
                    fontSize={"14px"}
                    lineHeight={"20px"}
                    width={"100px"}
                    textAlign={"center"}
                  >
                    Admin
                  </Typography>
                  <Typography
                    variant="h5"
                    fontSize={"14px"}
                    lineHeight={"20px"}
                    width={"100px"}
                    textAlign={"center"}
                  >
                    Member
                  </Typography>
                </Box>

                <Box
                  sx={{
                    marginTop: "10px",
                  }}
                >
                  {accessRights.map((accessRight, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        fontSize={"14px"}
                        lineHeight={"20px"}
                        width={"300px"}
                        fontWeight={"400"}
                        padding={"5px 10px"}
                      >
                        {accessRight.title}
                      </Typography>
                      <Box
                        sx={{
                          width: "100px",
                          textAlign: "center",
                        }}
                      >
                        <Checkbox checked={accessRight.admin} />
                      </Box>
                      <Box
                        sx={{
                          width: "100px",
                          textAlign: "center",
                        }}
                      >
                        <Checkbox checked={accessRight.member} />
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Modal
            open={errorModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                backgroundColor: colors.primary.white,
                border: `1px solid ${colors.secondary.iconGray}`,
                boxShadow: "0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
                padding: "30px",
                display: "flex",
                gap: "21px",
                width: "774px",
              }}
            >
              <Astronaut />
              <Box
                sx={{
                  flex: 1,
                }}
              >
                <Typography
                  variant="h3"
                  lineHeight={"normal"}
                  fontSize={"24px"}
                >
                  Error!
                </Typography>
                <Divider sx={{ margin: "20px 0" }} />
                <Typography
                  variant="h4"
                  lineHeight={"24px"}
                  fontSize={"18px"}
                  fontWeight={"400"}
                  marginBottom={"20px"}
                >
                  You do not have sufficient licenses in your plan to add more
                  team members.
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    sx={{
                      fontSize: "14px",
                      lineHeight: "normal",
                    }}
                    startIcon={
                      <ArrowBack
                        sx={{
                          width: "20px",
                          height: "20px",
                          lineHeight: "normal",
                        }}
                      />
                    }
                    variant="contained"
                    onClick={() =>
                      props.setActiveSetting?.("organisation-members")
                    }
                  >
                    Go Back
                  </Button>
                  <Button
                    sx={{
                      fontSize: "14px",
                      lineHeight: "normal",
                    }}
                    variant="contained"
                  >
                    See Plans & Pricing
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </React.Fragment>
      }
      setActiveSetting={props.setActiveSetting}
      onBack={() => props.setActiveSetting?.("organisation-members")}
    />
  );
};
