import React from "react";

export const CheckIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.833496" width="22" height="22" rx="11" fill="#0C162A" />
      <path
        d="M10.0257 14.6154C9.87141 14.6154 9.71715 14.5564 9.59965 14.4389L6.58686 11.4261C6.35126 11.1905 6.35126 10.8096 6.58686 10.574C6.82246 10.3384 7.20328 10.3384 7.43888 10.574L10.0257 13.1608L16.2278 6.95868C16.4634 6.72308 16.8442 6.72308 17.0798 6.95868C17.3154 7.19428 17.3154 7.5751 17.0798 7.8107L10.4517 14.4389C10.3342 14.5564 10.1799 14.6154 10.0257 14.6154Z"
        fill="white"
      />
    </svg>
  );
};
