import React from "react";

export const CheckGoodIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_2514_256483)">
        <mask id="path-1-inside-1_2514_256483" fill="white">
          <path d="M5.25009 9.43241L2.81759 6.99991L1.98926 7.82241L5.25009 11.0832L12.2501 4.08324L11.4276 3.26074L5.25009 9.43241Z" />
        </mask>
        <path
          d="M5.25009 9.43241L2.81759 6.99991L1.98926 7.82241L5.25009 11.0832L12.2501 4.08324L11.4276 3.26074L5.25009 9.43241Z"
          fill="#0C162A"
        />
        <path
          d="M5.25009 9.43241L3.12877 11.5537L5.24909 13.674L7.37041 11.5547L5.25009 9.43241ZM2.81759 6.99991L4.93891 4.87859L2.82507 2.76475L0.70378 4.87111L2.81759 6.99991ZM1.98926 7.82241L-0.124553 5.69361L-2.26089 7.8149L-0.132062 9.94373L1.98926 7.82241ZM5.25009 11.0832L3.12877 13.2046L5.25009 15.3259L7.37141 13.2046L5.25009 11.0832ZM12.2501 4.08324L14.3714 6.20456L16.4927 4.08324L14.3714 1.96192L12.2501 4.08324ZM11.4276 3.26074L13.5489 1.13942L11.4286 -0.980896L9.30727 1.13842L11.4276 3.26074ZM7.37141 7.31109L4.93891 4.87859L0.696271 9.12123L3.12877 11.5537L7.37141 7.31109ZM0.70378 4.87111L-0.124553 5.69361L4.10307 9.95121L4.9314 9.12871L0.70378 4.87111ZM-0.132062 9.94373L3.12877 13.2046L7.37141 8.96192L4.11058 5.70109L-0.132062 9.94373ZM7.37141 13.2046L14.3714 6.20456L10.1288 1.96192L3.12877 8.96192L7.37141 13.2046ZM14.3714 1.96192L13.5489 1.13942L9.30627 5.38206L10.1288 6.20456L14.3714 1.96192ZM9.30727 1.13842L3.12977 7.31009L7.37041 11.5547L13.5479 5.38306L9.30727 1.13842Z"
          fill="#0C162A"
          mask="url(#path-1-inside-1_2514_256483)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2514_256483">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
