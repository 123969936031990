import React from "react";

export const AssistanceLaptop = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="437"
      height="228"
      viewBox="0 0 437 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.22059 202.901C-11.7855 181.774 9.61564 125.25 41.8855 90.6832C81.1261 48.657 135.223 13.3054 239.789 25.327C326.133 35.2583 379.741 78.8051 397.976 101.238C426.971 136.89 447.919 187.192 430.456 207.997C401.152 242.918 280.074 183.501 214.4 182.289C148.725 181.078 52.8933 250.001 8.22059 202.901Z"
        fill="#9DB6E9"
      />
      <path
        d="M318.503 169.146H90.5862L42.6479 12.4306C42.1893 10.9339 42.0885 9.35031 42.3538 7.80747C42.619 6.26464 43.2428 4.80563 44.175 3.54794C45.1071 2.29024 46.3215 1.269 47.7205 0.566423C49.1194 -0.136149 50.6638 -0.500416 52.2293 -0.497047H259.207C261.35 -0.497474 263.436 0.190085 265.158 1.46438C266.881 2.73867 268.148 4.53236 268.775 6.58134L318.503 169.146Z"
        fill="#294C94"
      />
      <path
        d="M281.178 150.491H117.667C115.505 150.491 113.4 149.797 111.661 148.512C109.923 147.226 108.643 145.417 108.009 143.35L71.7066 24.6679C71.4756 23.9148 71.4246 23.118 71.5577 22.3416C71.6908 21.5653 72.0043 20.831 72.4729 20.1978C72.9416 19.5647 73.5523 19.0504 74.2559 18.6963C74.9596 18.3422 75.7365 18.1583 76.5242 18.1592H240.036C242.198 18.1593 244.303 18.8531 246.041 20.1385C247.78 21.424 249.06 23.2333 249.693 25.3004L285.996 143.982C286.227 144.735 286.278 145.532 286.145 146.308C286.012 147.085 285.698 147.819 285.23 148.452C284.761 149.085 284.15 149.6 283.447 149.954C282.743 150.308 281.966 150.492 281.178 150.491Z"
        fill="#EBF0FB"
      />
      <path
        d="M90.5869 169.146H396.729C398.794 169.146 400.775 169.967 402.236 171.427C403.696 172.888 404.516 174.868 404.516 176.934V187.336C404.516 189.401 403.696 191.382 402.236 192.842C400.775 194.303 398.794 195.123 396.729 195.123H98.374C97.3514 195.123 96.3388 194.922 95.394 194.53C94.4493 194.139 93.5908 193.565 92.8677 192.842C92.1446 192.119 91.571 191.261 91.1797 190.316C90.7883 189.371 90.5869 188.358 90.5869 187.336V169.146Z"
        fill="#182B54"
      />
      <path
        d="M127.898 90.0419L146.518 69.2598"
        stroke="#ED6060"
        strokeWidth="8.72014"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M150.914 90.0419L122.874 69.2598"
        stroke="#ED6060"
        strokeWidth="8.72014"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M219.845 90.0419L238.465 69.2598"
        stroke="#ED6060"
        strokeWidth="8.72014"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M242.863 90.0419L214.819 69.2598"
        stroke="#ED6060"
        strokeWidth="8.72014"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M203.245 99.5992C203.245 99.5992 188.648 107.646 173.823 99.5992"
        stroke="#ED6060"
        strokeWidth="8.72014"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M333.23 78.6483C331.543 78.644 329.908 78.0635 328.595 77.0031C327.283 75.9426 326.372 74.4658 326.013 72.817C324.64 66.6312 323.77 61.9078 324.981 57.4894C326.435 52.2502 330.27 49.182 334.401 46.2484C337.187 44.2747 339.12 41.2782 339.335 38.6227C339.47 36.8599 338.851 35.3751 337.384 33.9576C333.051 29.7546 326.035 36.2947 325.721 36.5773C325.721 36.5773 319.827 41.5653 315.449 36.335C311.071 31.1048 315.449 25.9193 315.449 25.9193C318.273 23.2972 321.522 21.1738 325.057 19.6394C333.486 16.0509 341.515 17.3966 347.67 23.3401C352.272 27.7988 354.546 33.6347 354.075 39.7711C353.533 46.8494 349.374 53.7977 342.946 58.3462C339.927 60.4859 339.358 61.2439 339.219 61.4862C338.873 62.5807 340.013 67.7078 340.443 69.6411C340.681 70.7218 340.674 71.842 340.422 72.9196C340.171 73.9971 339.681 75.0047 338.989 75.8684C338.298 76.7321 337.421 77.4301 336.425 77.9111C335.428 78.3921 334.337 78.644 333.23 78.6483Z"
        fill="#294C94"
      />
      <path
        d="M336.796 104.315C341.535 104.315 345.377 100.473 345.377 95.7339C345.377 90.9947 341.535 87.1528 336.796 87.1528C332.056 87.1528 328.215 90.9947 328.215 95.7339C328.215 100.473 332.056 104.315 336.796 104.315Z"
        fill="#294C94"
      />
    </svg>
  );
};
