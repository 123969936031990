import React from "react";
import Box from "@mui/material/Box";
import { useAuth0 } from "@auth0/auth0-react";

export const DebugPage: React.FC = () => {
  const { isLoading, isAuthenticated, user } = useAuth0();

  return (
    <Box>
      <h1>Debug Page</h1>
      <p>isLoading: {isLoading.toString()}</p>
      <p>isAuthenticated: {isAuthenticated.toString()}</p>
      <p>user: {JSON.stringify(user, null, 2)}</p>
    </Box>
  );
};
