import React from "react";
import get from "lodash/get";
import find from "lodash/find";
import { useUpdateEffect } from "react-use";
import { useAuth0 } from "@auth0/auth0-react";
import { useStoreActions, useStoreState } from "app/state/store/hooks";

export interface IFavourite {
  id: string;
  name: string;
  path: string;
}

export const useFavouritesUtils = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [token, setToken] = React.useState<string | null>(null);

  const count = useStoreState((state) =>
    get(state.GetBookmarks, "data.count", 0)
  );
  const favourites = useStoreState(
    (state) => get(state.GetBookmarks, "data.data", []) as IFavourite[]
  );
  const fetchFavouritesAction = useStoreActions(
    (actions) => actions.GetBookmarks.authGetFetch
  );
  const addFavouriteResponse = useStoreState(
    (state) => state.CreateBookmark.data
  );
  const addFavouriteAction = useStoreActions(
    (actions) => actions.CreateBookmark.authPostFetch
  );
  const removeFavouriteResponse = useStoreState(
    (state) => state.DeleteBookmark.data
  );
  const removeFavouriteAction = useStoreActions(
    (actions) => actions.DeleteBookmark.authDeleteFetch
  );
  const loading = useStoreState(
    (state) =>
      state.GetBookmarks.loading ||
      state.CreateBookmark.loading ||
      state.DeleteBookmark.loading
  );

  const getAccessTokenSilentlyWithState = async () => {
    if (isAuthenticated && !token) {
      const newToken = await getAccessTokenSilently();
      setToken(newToken);
      return newToken;
    }
    return token;
  };

  const fetchFavourites = async () => {
    if (!isAuthenticated) return;
    const token = await getAccessTokenSilentlyWithState();
    fetchFavouritesAction({
      dontEncryptAuthCall: true,
      values: { token },
    });
  };

  const addFavourite = async (values: {
    name: string;
    path: string;
    itemId: string;
  }) => {
    if (!isAuthenticated) return;
    const token = await getAccessTokenSilentlyWithState();
    addFavouriteAction({
      dontEncryptAuthCall: true,
      values: { ...values, userId: user?.sub, token },
    });
  };

  const removeFavourite = async (id: string) => {
    if (!isAuthenticated) return;
    const token = await getAccessTokenSilentlyWithState();
    removeFavouriteAction({
      dontEncryptAuthCall: true,
      values: { token },
      routeParams: { id },
    });
  };

  const onFavouriteButtonClick = (values: {
    name: string;
    path: string;
    itemId: string;
  }) => {
    const isFavourite = find(favourites, {
      itemId: values.itemId,
    }) as IFavourite;

    if (isFavourite) {
      removeFavourite(isFavourite.id);
    } else {
      addFavourite(values);
    }
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      fetchFavourites();
    }
  }, [isAuthenticated]);

  useUpdateEffect(() => {
    if (!addFavouriteResponse && !removeFavouriteResponse) return;
    fetchFavourites();
  }, [addFavouriteResponse, removeFavouriteResponse]);

  return {
    count,
    loading,
    favourites,
    addFavourite,
    fetchFavourites,
    removeFavourite,
    onFavouriteButtonClick,
  };
};
