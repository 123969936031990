import React from "react";
import { colors } from "app/theme";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { Autocomplete, SelectChangeEvent, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { ArrowDown } from "app/assets/vectors/jsx/arrow";
import { Close } from "@mui/icons-material";

interface IOption {
  value: string;
  label: string;
}

interface AutoCompleteFieldProps {
  id: string;
  value: IOption;
  options: IOption[];
  onChange?: (value: IOption | null) => void;
  variant?: "outlined" | "standard" | "filled";
  name?: string;
  label?: string;
  width?: string;
  height?: string;
  fontWeight?: string;
  fontSize?: string;
  placeholder?: string;
  borderColor?: string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement> & {
      key: any;
    },
    option: IOption
  ) => React.ReactNode;
}

export const AutoCompleteField: React.FC<AutoCompleteFieldProps> = (
  props: AutoCompleteFieldProps
) => {
  return (
    <FormControl
      fullWidth
      sx={{
        width: props.width ?? "100%",
        ".MuiInputBase-root": {
          width: props.width ?? "100%",
          height: props.height ?? "32px",
          borderRadius: "5px",
          background:
            props.variant === "outlined"
              ? colors.secondary.lightGrey
              : props.variant === "filled"
              ? colors.primary.blue
              : "transparent",
          border:
            props.variant === "outlined"
              ? `1px solid ${props.borderColor ?? colors.secondary.iconGray}`
              : "none",
        },
        ".MuiInputBase-input": {
          fontSize: props.fontSize ?? "12px",
          fontWeight: props.fontWeight ?? "700",
          lineHeight: "normal",
          textAlign: "start",
          padding: props.variant === "outlined" ? "10px 20px" : "10px 8px",
          color:
            props.variant === "filled"
              ? colors.primary.white
              : colors.text.title,
        },
        ".MuiOutlinedInput-notchedOutline": {
          borderStyle: "none",
        },
        ".MuiSelect-icon": {
          color: colors.primary.white,
        },
        ".MuiMenuItem-root": {
          fontSize: "12px",
          fontWeight: "700",
        },
        ".MuiSelect-paper": {
          width: props.width ?? "100%",
          background: `${colors.primary.green} !important`,
          padding: "100px",
          borderRadius: "10px",
          border: `1px solid ${colors.secondary.iconGray}`,
        },
      }}
    >
      {props.label ? (
        <Typography
          lineHeight={"normal"}
          variant="body2"
          fontSize={"12px"}
          component={"label"}
          htmlFor={props.id}
          marginBottom={"5px"}
        >
          {props.label}
        </Typography>
      ) : null}
      <Autocomplete
        id={props.id}
        onChange={(e, value) => props?.onChange?.(value)}
        value={props.value}
        options={props.options}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderOption={props.renderOption}
        popupIcon={
          <ArrowDown
            stroke={props.variant === "filled" ? "white" : colors.text.title}
            strokeWidth={props.variant === "filled" ? 0.2 : 1}
          />
        }
        renderInput={(params) => <TextField {...params} />}
        sx={{
          ".MuiSelect-select": {
            minHeight: "auto !important",
          },
        }}
        slotProps={{
          paper: {
            className: "autocomplete-paper",
            sx: {
              width: props.width ?? "100%",
              background: colors.secondary.lightGrey,
              padding: "10px",
              borderRadius: "10px",
              border: `1px solid ${colors.secondary.iconGray}`,
              maxHeight: "350px",
              overflowY: "hidden",
              marginTop: "8px",
              fontSize: props.fontSize ?? "12px",
              fontWeight: props.fontWeight ?? "700",
              textAlign: "start",
              lineHeight: "normal",
              "& ul": {
                maxHeight: "330px",
                "& li[aria-selected='true']": {
                  background: colors.secondary.disableButton,
                  borderRadius: "10px",
                },
                "& li": {
                  marginBottom: "10px",
                  ":hover": {
                    borderRadius: "10px",
                  },
                },
                ".Mui-focused": {
                  borderRadius: "10px",
                },
              },
            },
          },
        }}
      />
    </FormControl>
  );
};
