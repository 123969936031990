import React from "react";
import theme from "app/theme";
import { store } from "app/state/store";
import { Auth0Provider } from "@auth0/auth0-react";
import { HelmetProvider } from "react-helmet-async";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { PageLoader } from "app/components/page-loader";
import { StoreProvider, useStoreRehydrated } from "easy-peasy";

interface ProviderProps {
  children?: any;
}

function Providers(props: ProviderProps) {
  return (
    <HelmetProvider>
      <StoreProvider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Auth0Provider
            useRefreshTokens
            useRefreshTokensFallback
            cacheLocation="localstorage"
            domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
            clientId={process.env.REACT_APP_AUTH0_CLIENT as string}
            authorizationParams={{
              scope: "openid profile email offline_access",
              audience: process.env.REACT_APP_AUTH0_AUDIENCE,
              redirect_uri: `${window.location.origin}/callback`,
            }}
          >
            <AppContainer>{props.children}</AppContainer>
          </Auth0Provider>
        </ThemeProvider>
      </StoreProvider>
    </HelmetProvider>
  );
}

export default Providers;

function AppContainer(props: ProviderProps) {
  const isRehydrated = useStoreRehydrated();
  if (!isRehydrated) {
    return <PageLoader />;
  }
  return <React.Fragment>{props.children}</React.Fragment>;
}
