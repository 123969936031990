import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Popper from "@mui/material/Popper";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { GenericTable } from "app/components/generic-table";
import { LinearProgress } from "app/components/linear-progress";
import {
  SECTOR_COLUMNS,
  ACTIVITY_COLUMNS,
  LOCATION_COLUMNS,
  ORGANISATION_COLUMNS,
} from "app/pages/explorer/components/search/tableColumns";
import {
  ExplorerSearchResultsPopupProps,
  TabProps,
  TabType,
  ButtonStyle,
} from "app/pages/explorer/components/search/data";

const Tab = (props: TabProps) => {
  const active = props.activeTab === props.value;

  return (
    <Box
      sx={{
        width: "200px",
        padding: "8px 0px",
        borderBottom: `3px solid ${
          active ? colors.primary.blue : colors.shades.blue["200"]
        }`,
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={() => props.setActiveTab(props.value)}
    >
      <Typography variant="h5" fontSize="14px">
        {props.children}
      </Typography>
    </Box>
  );
};

export const ExplorerSearchResultsPopup = (
  props: ExplorerSearchResultsPopupProps
) => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = React.useState<TabType>("activities");

  const getTableContent = (tab: TabType) => {
    return {
      activities: {
        columns: ACTIVITY_COLUMNS,
        rows: props.results.activities.items,
      },
      locations: {
        columns: LOCATION_COLUMNS,
        rows: props.results.locations.items,
      },
      organisations: {
        columns: ORGANISATION_COLUMNS,
        rows: props.results.organisations.items,
      },
      sectors: { columns: SECTOR_COLUMNS, rows: props.results.sectors.items },
    }[tab];
  };

  const tableContent = React.useMemo(
    () => getTableContent(activeTab),
    [activeTab, props.results]
  );

  const onRowClick = (row: any) => {
    switch (activeTab) {
      case "activities":
        navigate(`/explorer/activities/${row.id}`);
        break;
      case "locations":
        navigate(`/explorer/locations/${row.id}`);
        break;
      case "organisations":
        navigate(`/explorer/organisations/${row.id}`);
        break;
      case "sectors":
        navigate(`/explorer/sectors/${row.id}`);
        break;
      default:
        break;
    }
  };

  const open = Boolean(props.anchorEl);
  const id = open ? props.id : undefined;

  return (
    <Popper
      id={id}
      open={open}
      placement="bottom"
      sx={{ zIndex: 3 }}
      anchorEl={props.anchorEl}
    >
      <Box
        sx={{
          width: "1058px",
          padding: "16px",
          marginTop: "7px",
          borderRadius: "5px",
          border: "1px solid #A1AEBD",
          background: colors.secondary.lightGrey,
        }}
      >
        <Grid display="flex" justifyContent="space-between">
          <Tab
            value="activities"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          >
            Activities ({props.results.activities.count})
          </Tab>
          <Tab
            value="locations"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          >
            Locations ({props.results.locations.count})
          </Tab>
          <Tab
            value="organisations"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          >
            Organisations ({props.results.organisations.count})
          </Tab>
          <Tab
            value="sectors"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          >
            Sectors ({props.results.sectors.count})
          </Tab>
        </Grid>
        <Box
          sx={{
            height: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "> span": {
              width: "100%",
            },
          }}
        >
          {props.loading && <LinearProgress />}
        </Box>
        <Box>
          <GenericTable
            infiniteScroll
            id="explorer-table"
            loading={props.loading}
            onRowClick={onRowClick}
            rows={tableContent.rows}
            loadMore={props.loadMore}
            columns={tableContent.columns}
            rowStyle={{ cursor: "pointer" }}
            hasMore={props.results.activities.hasMore}
          />
        </Box>
        <Box height={10} />
        <Grid display="flex" justifyContent="flex-end" columnGap="10px">
          <Button
            sx={ButtonStyle}
            variant="contained"
            onClick={props.handleClose}
          >
            Close
          </Button>
          <Button
            sx={ButtonStyle}
            variant="contained"
            endIcon={<ArrowForward />}
            onClick={() =>
              navigate(`/explorer/activities?search=${props.searchValue}`)
            }
          >
            Search
          </Button>
        </Grid>
      </Box>
    </Popper>
  );
};
