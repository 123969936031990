import React from "react";

const CheckYesIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2514_256471)">
        <path
          d="M16.2753 24.795L16.6447 25.1602L17.0143 24.7952L30.2526 11.7197L31.3168 12.7719L16.6449 27.2772L10.2109 20.9163L11.2853 19.8617L16.2753 24.795Z"
          fill="#0C162A"
          stroke="#0C162A"
          strokeWidth="1.05134"
        />
      </g>
      <circle
        cx="20.5"
        cy="20"
        r="19.0993"
        stroke="#0C162A"
        strokeWidth="1.05134"
      />
      <defs>
        <clipPath id="clip0_2514_256471">
          <rect
            width="30.8393"
            height="30.4888"
            fill="white"
            transform="translate(5.08008 3.87952)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckYesIcon;
