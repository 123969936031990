import React from "react";
import { useSearchParams } from "react-router-dom";

export const useExpandedBlock = (props: { blockIds: string[] }) => {
  const [currentQueryParameters, setSearchParams] = useSearchParams();
  const [expandedBlock, setExpandedBlock] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (
      currentQueryParameters.get("e") &&
      props.blockIds.includes(currentQueryParameters.get("e") as string)
    ) {
      setExpandedBlock(currentQueryParameters.get("e") as typeof expandedBlock);
    }
  }, [currentQueryParameters]);

  const handleSetExpandedBlock = (block: typeof expandedBlock) => {
    const newQueryParameters: URLSearchParams = new URLSearchParams();
    setExpandedBlock((prev) => (prev === block ? null : block));
    if (block && block !== expandedBlock) {
      newQueryParameters.set("e", block);
    } else {
      newQueryParameters.delete("e");
    }
    setSearchParams(newQueryParameters);
  };

  return { expandedBlock, handleSetExpandedBlock };
};
