import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export const PageLoader = () => {
  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        zIndex: 100000,
        width: "100vw",
        height: "100vh",
        display: "flex",
        position: "fixed",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(0, 0, 0, 0.2)",
      }}
    >
      <Box
        sx={{
          margin: 0,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: "calc(100vh - 45px)",
        }}
      >
        <Box
          sx={{
            width: "55px",
            height: "55px",
            display: "flex",
            borderRadius: "5px",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            background: colors.primary.white,
          }}
        >
          <CircularProgress disableShrink />
        </Box>
      </Box>
    </Box>
  );
};

export const InlineSmallLoader = () => {
  return (
    <Box
      sx={{
        height: "32px",
        position: "relative",
        "> svg": {
          top: -9,
          left: -9,
          position: "absolute",
          transform: "scale(0.65)",
        },
      }}
    >
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none">
        <path
          d="M44 24C44 26.6264 43.4827 29.2272 42.4776 31.6537C41.4725 34.0802 39.9993 36.285 38.1421 38.1421C36.285 39.9993 34.0802 41.4725 31.6537 42.4776C29.2272 43.4827 26.6264 44 24 44C21.3736 44 18.7728 43.4827 16.3463 42.4776C13.9198 41.4725 11.715 39.9993 9.85786 38.1421C8.00069 36.285 6.5275 34.0802 5.52241 31.6537C4.51731 29.2272 4 26.6264 4 24C4 21.3736 4.51732 18.7728 5.52241 16.3463C6.5275 13.9198 8.00069 11.715 9.85787 9.85786C11.715 8.00069 13.9198 6.5275 16.3463 5.52241C18.7728 4.51731 21.3736 4 24 4C26.6264 4 29.2272 4.51732 31.6537 5.52241C34.0802 6.5275 36.285 8.00069 38.1421 9.85787C39.9993 11.715 41.4725 13.9198 42.4776 16.3463C43.4827 18.7728 44 21.3736 44 24L44 24Z"
          stroke="#D7D8D9"
          strokeWidth="8"
        />
        <path
          d="M44 24C44 24.0419 43.9999 24.0838 43.9996 24.1257"
          stroke="#3B6CD3"
          strokeWidth="8"
        />
      </svg>
      <CircularProgress
        size={30}
        disableShrink
        thickness={7}
        color="secondary"
      />
    </Box>
  );
};
