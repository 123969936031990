import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Table } from "app/components/table";
import Typography from "@mui/material/Typography";
import { formatLocaleN } from "app/utils/formatLocale";
import { PageHeader } from "app/components/page-header";
import { useUnmount, useUpdateEffect } from "react-use";
import { ShareButton } from "app/components/share-button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DownloadButton } from "app/components/download-button";
import { useFavouritesUtils } from "app/hooks/useFavouritesUtils";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { FilterBlock } from "app/pages/explorer/pages/activities/components/filter-block";
import { getActivitiesColumns } from "app/pages/home/explorer/components/live-data-updates/tableColumns";
import { ReactComponent as ExplorerActivitiesHeaderIcon } from "app/assets/vectors/ExplorerActivitiesHeaderIcon.svg";

const box = <Box height={40} />;

export const ExplorerActivities: React.FC = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const { favourites, onFavouriteButtonClick } = useFavouritesUtils();

  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [searchBtnEnabled, setSearchBtnEnabled] = React.useState(false);
  const [filters, setFilters] = React.useState({
    location: [],
    organisation: [],
    sector: [],
    year1: "",
    year2: "",
    activityStatus: [],
    budgetMin: "",
    budgetMax: "",
  });

  const loading = useStoreState((state) => state.ExplorerActivityTable.loading);
  const data = useStoreState((state) =>
    get(state.ExplorerActivityTable, "data.data", [])
  );
  const count = useStoreState((state) =>
    get(state.ExplorerActivityTable, "data.count", 0)
  );
  const fetch = useStoreActions(
    (actions) => actions.ExplorerActivityTable.fetch
  );
  const clear = useStoreActions(
    (actions) => actions.ExplorerActivityTable.clear
  );

  const periodRouteParam = React.useMemo(() => {
    if (filters.year1 && filters.year2) {
      return `${filters.year1}-01-01|${filters.year2}-12-31`;
    }
    if (filters.year1) {
      return `${filters.year1}-01-01|${filters.year1}-12-31`;
    }
    if (filters.year2) {
      return `${filters.year2}-01-01|${filters.year2}-12-31`;
    }
    return null;
  }, [filters.year1, filters.year2]);

  const onLoadMore = () => {
    if (loading) return;
    setPage((prev) => prev + 1);
  };

  const reload = (addOnData?: boolean, noFilters?: boolean) => {
    let filterStringArr = [];
    if (search) {
      filterStringArr.push(`search=${search}`);
    }
    if (filters.location.length > 0) {
      filterStringArr.push(`country=${filters.location.join(",")}`);
    }
    if (filters.organisation.length > 0) {
      filterStringArr.push(`publisher=${filters.organisation.join(",")}`);
    }
    if (filters.sector.length > 0) {
      filterStringArr.push(`sector=${filters.sector.join(",")}`);
    }
    if (filters.activityStatus.length > 0) {
      filterStringArr.push(
        `activityStatus=${filters.activityStatus.join(",")}`
      );
    }
    if (filters.budgetMin) {
      filterStringArr.push(`budgetMin=${filters.budgetMin}`);
    }
    if (filters.budgetMax) {
      filterStringArr.push(`budgetMax=${filters.budgetMax}`);
    }
    if (periodRouteParam) {
      filterStringArr.push(`period=${periodRouteParam}`);
    }
    if (noFilters) {
      filterStringArr = [];
    }
    fetch({
      addOnData,
      filterString: filterStringArr.join("&"),
      routeParams: {
        page: page.toString(),
      },
    });
  };

  const onSearchBtnClick = () => {
    reload();
    setSearchBtnEnabled(false);
    if (search) {
      setSearchParams({ search });
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  };

  const onFilterChange = (
    value: string | string[],
    type: string,
    action: "add" | "remove"
  ) => {
    if (!Object.keys(filters).includes(type)) return;
    const multiFilter = [
      "location",
      "organisation",
      "sector",
      "activityStatus",
    ].includes(type);
    let newFilters: string | string[] = get(
      filters,
      type,
      multiFilter ? [] : ""
    );
    if (action === "add") {
      newFilters = value;
    } else {
      newFilters = multiFilter
        ? (newFilters as string[]).filter((item: string) => item !== value)
        : "";
    }
    setFilters({
      ...filters,
      [type]: newFilters,
    });
  };

  const resetFilters = () => {
    setFilters({
      location: [],
      organisation: [],
      sector: [],
      year1: "",
      year2: "",
      activityStatus: [],
      budgetMin: "",
      budgetMax: "",
    });
    reload(false, true);
    setSearch("");
    searchParams.delete("search");
    setSearchParams(searchParams);
    setSearchBtnEnabled(false);
  };

  const onTableRowClick = (params: any) => {
    navigate(`/explorer/activities/${params.id}`);
  };

  React.useEffect(() => reload(true), [page]);

  useUpdateEffect(() => {
    setSearchBtnEnabled(
      search.length > 0 ||
        Object.values(filters).some((filter) => filter.length > 0)
    );
  }, [search, filters]);

  React.useEffect(() => {
    const v = searchParams.get("search");
    if (v && v !== search) {
      setSearch(v);
      onSearchBtnClick();
    }
  }, [searchParams.get("search")]);

  useUnmount(() => {
    clear();
  });

  const columns = React.useMemo(() => {
    const cols = getActivitiesColumns(favourites, onFavouriteButtonClick);
    return cols;
  }, [favourites]);

  const table = React.useMemo(() => {
    return (
      <Table
        rows={data}
        loading={loading}
        columns={columns}
        autoHeight={false}
        headerFontSize="12px"
        loadMore={onLoadMore}
        onRowClick={onTableRowClick}
        hasMore={count > data.length}
      />
    );
  }, [data, count, onLoadMore, loading]);

  return (
    <Box>
      <PageHeader
        variant="explorer"
        title="AIDA Explorer: Search & Filter Activities"
        subtitle="Delve into the heart of humanitarian work with AIDA's activity explorer. Search, filter, and explore a vast array of aid projects and initiatives from around the world."
        icon={<ExplorerActivitiesHeaderIcon />}
      />
      {box}
      <Grid display="flex" justifyContent="space-between">
        <Typography variant="h2" fontSize="36px">
          Discover Impact: Search and Filter Activities
        </Typography>
        <Grid display="flex" alignItems="center" columnGap="8px">
          <ShareButton id="share-button" />
          <DownloadButton id="download-button" />
        </Grid>
      </Grid>
      {box}
      <FilterBlock
        count={count}
        search={search}
        setSearch={setSearch}
        selectedFilters={filters}
        resetFilters={resetFilters}
        onFilterChange={onFilterChange}
        searchBtnEnabled={searchBtnEnabled}
        onSearchBtnClick={onSearchBtnClick}
      />
      {box}
      <GenericPageBlockItem
        title="RECENT ACTIVITIES"
        altLoading={loading}
        description={
          <React.Fragment>
            There's currently{" "}
            <span style={{ color: colors.primary.blue }}>
              {formatLocaleN(count)} activities{" "}
            </span>
            according to your search results.
          </React.Fragment>
        }
        content={table}
        toolbarShare
      />
    </Box>
  );
};
