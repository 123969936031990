import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Close from "@mui/icons-material/Close";
import { FileRejection } from "react-dropzone";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useSearchParams } from "react-router-dom";
import { DropZone } from "app/components/dropzone";
import { InlineSmallLoader } from "app/components/page-loader";
import { ReactComponent as FileIcon } from "app/assets/vectors/PublisherFileUploadFile.svg";
import { ReactComponent as WarningIcon } from "app/assets/vectors/PublisherWarningSymbol.svg";
import { ReactComponent as SearchIcon } from "app/assets/vectors/PublisherFileUploadSearch.svg";
import { ReactComponent as LocalUploadIcon } from "app/assets/vectors/PublisherFileUploadComputer.svg";
import { ReactComponent as GoogleDriveIcon } from "app/assets/vectors/PublisherFileUploadGoogleDrive.svg";
import { ReactComponent as MicrosoftCloudIcon } from "app/assets/vectors/PublisherFileUploadMicrosoftCloud.svg";

interface PublisherBlockUploadViewProps {
  message: string;
  filename: string;
  uploading: boolean;
  selectedFiles: File[];
  rejectedFiles: FileRejection[];
  setFilename: (filename: string) => void;
  setSelectedFiles: (files: File[]) => void;
  setRejectedFiles: (files: FileRejection[]) => void;
}

export const PublisherBlockUploadView: React.FC<
  PublisherBlockUploadViewProps
> = (props: PublisherBlockUploadViewProps) => {
  const params = useSearchParams()[0];

  const inputFileRef = React.useRef<HTMLInputElement>();

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    props.setSelectedFiles(acceptedFiles);
    props.setRejectedFiles(fileRejections);
  };

  const onLocalUploadBtnClick = () => {
    inputFileRef.current?.click();
  };

  const onRemoveFile = (file: File) => () => {
    const newFiles = props.selectedFiles.filter((f) => f !== file);
    props.setSelectedFiles(newFiles);
  };

  const content = React.useMemo(() => {
    if (props.selectedFiles.length > 0) {
      return (
        <Box
          sx={{
            gap: "15px",
            display: "flex",
            padding: "20px",
            borderRadius: "10px",
            flexDirection: "column",
            border: `1px dashed ${colors.secondary.iconGray}`,
          }}
        >
          <Box
            sx={{
              gap: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" fontWeight="700" fontSize="12px">
              Add a File Name
            </Typography>
            <input
              type="text"
              value={props.filename}
              placeholder="File Name*"
              onChange={(e) => props.setFilename(e.target.value)}
              style={{
                width: "250px",
                height: "32px",
                outline: "none",
                borderRadius: "5px",
                padding: "0px 20px",
                background: colors.secondary.lightGrey,
                border: `1px solid ${colors.secondary.iconGray}`,
              }}
            />
          </Box>
          <Box
            sx={{
              gap: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "32px",
            }}
          >
            {props.uploading && <InlineSmallLoader />}
            {props.message && (
              <Typography variant="h6" fontSize="12px" fontWeight="normal">
                {props.message}
              </Typography>
            )}
          </Box>
          <Box
            className="publisher-scrollbar"
            sx={{
              gap: "10px",
              width: "100%",
              display: "flex",
              maxWidth: "100%",
              overflowX: "auto",
              paddingTop: "20px",
              flexDirection: "row",
              paddingBottom: "15px",
              "> div": {
                gap: "5px",
                width: "80px",
                height: "80px",
                display: "flex",
                minWidth: "80px",
                maxWidth: "80px",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                background: colors.shades.green[100],
              },
            }}
          >
            {props.selectedFiles.map((file) => (
              <Box
                key={file.name}
                sx={{
                  position: "relative",
                  "&:hover": {
                    "> #close-icon": {
                      display: "block",
                    },
                  },
                }}
              >
                <Box
                  id="close-icon"
                  sx={{
                    top: "-14px",
                    right: "-9px",
                    display: "none",
                    borderRadius: "50%",
                    position: "absolute",
                    color: colors.primary.green,
                    background: colors.primary.white,
                    "> button": {
                      padding: "0",
                    },
                  }}
                >
                  <IconButton onClick={onRemoveFile(file)}>
                    <Close fontSize="small" />
                  </IconButton>
                </Box>
                <FileIcon />
                <Typography
                  variant="h6"
                  fontSize="10px"
                  fontWeight="normal"
                  sx={{
                    maxWidth: "60px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {file.name}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      );
    }
    return (
      <DropZone
        onDrop={onDrop}
        disabled={false}
        uploadError={false}
        inputRef={inputFileRef}
      />
    );
  }, [props.selectedFiles, props.message, onRemoveFile]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "450px",
        display: "flex",
        flexDirection: "column",
        a: {
          color: "inherit",
        },
      }}
    >
      {params.get("fileId") && (
        <Box
          sx={{
            gap: "10px",
            width: "100%",
            display: "flex",
            padding: "15px",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "20px",
            background: colors.secondary.lightGrey,
          }}
        >
          <WarningIcon />
          <Typography variant="h5" fontSize="14px">
            Editing a file means overwriting the previous file data, please
            ensure you upload all necessary spreadsheets.
          </Typography>
        </Box>
      )}
      {content}
      <Box
        sx={{
          gap: "20px",
          width: "100%",
          display: "flex",
          marginTop: "20px",
          flexDirection: "row",
          justifyContent: "center",
          "> button, div": {
            fontSize: "12px",
            minWidth: "150px",
            fontWeight: "bold",
            borderRadius: "5px",
            padding: "4px 12px",
            color: colors.primary.white,
            background: colors.primary.green,
            "&:hover": {
              background: colors.shades.green[500],
              "> input": {
                background: colors.shades.green[500],
              },
            },
            "> input": {
              width: "230px",
              outline: "none",
              color: colors.primary.white,
              // background: colors.primary.green,
              background: colors.secondary.lightGrayText,
              borderStyle: "none none solid none",
              // borderColor: colors.shades.green[200],
              borderColor: colors.primary.white,
              "::placeholder": {
                fontWeight: "700",
                // color: colors.shades.green[200],
                color: colors.primary.white,
              },
            },
          },
        }}
      >
        <Button startIcon={<LocalUploadIcon />} onClick={onLocalUploadBtnClick}>
          Local upload
        </Button>
        <Button startIcon={<GoogleDriveIcon />} disabled>
          Connect to Google Drive
        </Button>
        <Button startIcon={<MicrosoftCloudIcon />} disabled>
          Connect to Microsoft Cloud
        </Button>
        <Box
          sx={{
            gap: "10px",
            display: "flex",
            alignItems: "center",
            pointerEvents: "none",
            background: `${colors.secondary.lightGrayText} !important`,
          }}
        >
          <SearchIcon />
          <input
            type="text"
            placeholder="Enter URL and locate your IATI file"
          />
        </Box>
      </Box>
      <Box
        sx={{
          gap: "10px",
          width: "100%",
          display: "flex",
          padding: "15px",
          marginTop: "20px",
          flexDirection: "row",
          alignItems: "center",
          background: colors.secondary.lightGrey,
        }}
      >
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.43876 3.56345C4.19411 3.78538 3.33773 4.75923 3.19976 6.02078C3.0216 7.6498 2.83203 10.4372 2.83203 14.9233C2.83203 19.4093 3.0216 22.1967 3.19976 23.8257C3.33773 25.0873 4.19411 26.0612 5.43876 26.2831C6.54491 26.4803 8.20701 26.6961 10.6237 26.8414V29.7424C10.6237 30.935 11.9978 31.598 12.9254 30.853L17.7092 27.0107C23.4178 26.9818 26.7398 26.6073 28.5586 26.2831C29.8033 26.0612 30.6597 25.0873 30.7977 23.8257C30.9758 22.1967 31.1654 19.4093 31.1654 14.9233C31.1654 10.4372 30.9758 7.6498 30.7977 6.02077C30.6597 4.75923 29.8033 3.78537 28.5586 3.56344C26.6656 3.22591 23.1443 2.83398 16.9987 2.83398C10.8531 2.83398 7.33179 3.22591 5.43876 3.56345Z"
            fill="#66BCB9"
          />
          <path
            d="M14.258 14.5173C14.0491 14.7151 13.9486 14.983 13.9566 15.3209H13.9557V16.891C13.9557 17.5078 14.4411 18.008 15.04 18.008H16.0538C16.6526 18.008 17.1381 17.5078 17.1381 16.891V16.5454L17.29 16.4725C17.9701 16.1412 18.5731 15.7975 19.0993 15.4411C19.6254 15.0846 20.0519 14.6968 20.379 14.2778C20.7196 13.8571 20.9592 13.3911 21.0976 12.8798C21.2483 12.3529 21.2882 11.7535 21.2172 11.0816C21.0724 9.70951 20.5359 8.66472 19.6076 7.94711C18.6929 7.22794 17.4338 6.95821 15.8303 7.13784C15.2731 7.20031 14.7558 7.31492 14.2784 7.48173C13.7995 7.63452 13.3877 7.83647 13.043 8.08764C12.6983 8.33874 12.4334 8.63044 12.2485 8.96279C12.0772 9.29365 12.013 9.66205 12.0558 10.0681C12.0888 10.3806 12.1911 10.6568 12.3514 10.8899C12.6886 11.3803 13.3619 11.2195 13.8481 10.8875C13.9699 10.8044 14.0943 10.7266 14.2214 10.6541C14.622 10.411 15.1009 10.2581 15.6581 10.1957C16.2696 10.1272 16.7207 10.2325 17.0114 10.5116C17.3157 10.7891 17.4871 11.1099 17.5255 11.4739C17.5684 11.8799 17.4822 12.2366 17.2672 12.544C17.0507 12.8374 16.7467 13.0839 16.3553 13.2836L15.181 13.8827C14.8032 14.0809 14.4955 14.2924 14.258 14.5173Z"
            fill="#004845"
          />
          <path
            d="M13.541 21.388C13.541 20.7966 13.7119 20.3177 14.0536 19.9516C14.409 19.5854 14.8943 19.4023 15.5094 19.4023C16.1245 19.4023 16.6029 19.5854 16.9447 19.9516C17.3001 20.3177 17.4778 20.7966 17.4778 21.388C17.4778 21.9795 17.3001 22.4583 16.9447 22.8244C16.6029 23.1906 16.1245 23.3737 15.5094 23.3737C14.8943 23.3737 14.409 23.1906 14.0536 22.8244C13.7119 22.4583 13.541 21.9795 13.541 21.388Z"
            fill="#004845"
          />
        </svg>
        <Box
          sx={{
            gap: "5px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5" fontSize="14px">
            Don't know where to start?
          </Typography>
          <Box>
            <Typography fontSize="12px" component="div">
              Try uploading our <a href="#">Gold Standard Files</a> to see how
              the publishing process works.
            </Typography>
            <Typography fontSize="12px" component="div">
              Or,{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.REACT_APP_ASA_API}/file-templates/activity.zip`}
              >
                Download spreadsheet templates
              </a>{" "}
              to help you get started with your own files. (10 MB)
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
