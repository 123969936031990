import React from "react";
import axios, { AxiosResponse, AxiosError } from "axios";
import { emailValidation } from "app/utils/emailValidation";

export const useEmailSignup = () => {
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  function handleSubmit() {
    if (!emailValidation(email)) {
      setMessage("Please provide a valid email address");
    } else {
      axios
        .post(`${process.env.REACT_APP_API}/subscribe-to-newsletter`, {
          email,
        })
        .then((response: AxiosResponse) => {
          if (response.status === 200 && !response.data.error) {
            setEmail("");
            setMessage("Thank you for subscribing!");
          } else {
            setMessage("Something went wrong, please try again later");
          }
        })
        .catch((error: AxiosError) => {
          console.log(error.response);
          setMessage("Something went wrong, please try again later");
        });
    }
  }
  return { handleSubmit, message, email, setEmail, setMessage };
};
