import React from "react";

export const SampleFiles = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="19" height="22" viewBox="0 0 19 22" fill="none" {...props}>
      <path
        d="M9.3 10.009H15.7V10.0462H9.3V10.009Z"
        fill="#001D1C"
        stroke="#001D1C"
        strokeWidth="1.6"
      />
      <path
        d="M9.3 16.5588H12.5V16.596H9.3V16.5588Z"
        fill="#001D1C"
        stroke="#001D1C"
        strokeWidth="1.6"
      />
      <path
        d="M9.3 13.2834H15.7V13.3206H9.3V13.2834Z"
        fill="#001D1C"
        stroke="#001D1C"
        strokeWidth="1.6"
      />
      <path
        d="M1.83333 6.39535H0.5V5.11628H1.83333V6.39535ZM1.83333 8.95349H0.5V7.67442H1.83333V8.95349ZM1.83333 11.5116H0.5V10.2326H1.83333V11.5116ZM1.83333 14.0698H0.5V12.7907H1.83333V14.0698ZM1.83333 16.6279H0.5V15.3488H1.83333V16.6279ZM1.83333 17.907V19.186H0.5V17.907H1.83333ZM1.83333 19.186H3.16667V20.4651H1.83333V19.186ZM4.5 19.186H5.83333V20.4651H4.5V19.186ZM1.83333 1.27907H0.5V0H1.83333V1.27907ZM4.5 1.27907H3.16667V0H4.5V1.27907ZM5.83333 0H7.16667V1.27907H5.83333V0ZM9.83333 1.27907H8.5V0H9.83333V1.27907ZM12.5 1.27907H11.1667V0H12.5V1.27907ZM15.1667 1.27907H13.8333V0H15.1667V1.27907ZM15.1667 2.55814V1.27907H16.5V2.55814H15.1667ZM16.5 5.11628H15.1667V3.83721H16.5V5.11628ZM1.83333 3.83721H0.5V2.55814H1.83333V3.83721Z"
        fill="#001D1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 6.65109L7 5.11621H17.5L19 6.65109V20.465L17.5 21.9999H7L5.5 20.465V6.65109ZM7.5 7V13.5V20H17.5V7H12H7.5Z"
        fill="#001D1C"
      />
    </svg>
  );
};
