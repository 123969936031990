import React from "react";
import { colors } from "app/theme";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";

interface InfoTooltipProps {
  arrow?: boolean;
  mode: "light" | "dark";
  children: React.ReactElement;
  info?: string | React.ReactNode;
}

export const InfoTooltip: React.FC<InfoTooltipProps> = (
  props: InfoTooltipProps
) => {
  const CustomTooltip = React.useMemo(() => {
    return styled(({ className, ...tprops }: TooltipProps) => (
      <Tooltip {...tprops} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        padding: "10px",
        fontSize: "10px",
        borderRadius: "4px",
        boxShadow: theme.shadows[1],
        color:
          props.mode === "dark"
            ? colors.primary.white
            : colors.secondary.darkGrey,
        background:
          props.mode === "dark"
            ? colors.secondary.darkGrey
            : colors.primary.white,
      },
      [`& .${tooltipClasses.arrow}`]: {
        borderColor: props.mode === "light" ? colors.secondary.darkGrey : "",
        color:
          props.mode === "dark"
            ? colors.secondary.darkGrey
            : colors.primary.white,
      },
    }));
  }, [props.mode]);

  return (
    <CustomTooltip arrow={props.arrow} title={props.info}>
      {props.children}
    </CustomTooltip>
  );
};
