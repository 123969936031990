import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useHoverDirty, useMeasure } from "react-use";
import { ChartTooltip } from "app/components/charts/tooltip";
import { SDGChartProps } from "app/components/charts/sdg/data";

const SDGChartItem: React.FC<{
  expanded?: boolean;
  labelGroup?: string;
  data: SDGChartProps["data"][0];
}> = (props) => {
  const ref = React.useRef(null);
  const isHovering = useHoverDirty(ref);
  const [refContent, { width, height }] = useMeasure();

  return (
    <Grid
      item
      xs={6}
      sm={4}
      ref={ref}
      md={props.expanded ? 1.3 : 3.7}
      sx={{ cursor: "pointer", position: "relative" }}
    >
      <Box
        ref={refContent}
        sx={{
          width: "100%",
          "> img": {
            width: "100%",
            height: "100%",
            objectFit: "contain",
          },
        }}
      >
        <img
          src={`/sdgs/en/${props.data.sdg}.png`}
          alt={`SDG ${props.data.sdg}`}
        />
      </Box>
      {props.expanded ? (
        <Box
          sx={{
            padding: "10px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6">{props.data.value}</Typography>
          <Typography variant="body2" fontSize="12px" marginTop="5px">
            {props.labelGroup}
          </Typography>
        </Box>
      ) : null}
      {isHovering && (
        <ChartTooltip
          top={height / 2}
          left={(width / 3) * -1}
          title={`${props.data.value} ${props.labelGroup} for ${props.data.name}`}
          subtitle="This data is taking account of the activities that have reported their related SDGs."
        />
      )}
    </Grid>
  );
};

export const SDGChart: React.FC<SDGChartProps> = (props: SDGChartProps) => {
  const gap = props.expanded ? "20px" : "10px";

  return (
    <Grid display="flex" gap={gap} flexWrap="wrap">
      {props.data.map((item) => (
        <SDGChartItem key={item.sdg} {...props} data={item} />
      ))}
    </Grid>
  );
};
