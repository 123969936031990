import axios from "axios";
import get from "lodash/get";
import find from "lodash/find";
import { colors } from "app/theme";
import { ConvertedFile } from "app/components/table/data";
import { PublisherBlockValidityReportPopupProps } from "app/pages/publisher/components/publisher-block/views/validityReportPopup";

export const retrieveFileValidaionReport = async (
  fileId: string,
  convertedFiles: ConvertedFile[],
  token: string,
  setLoading: (v: boolean) => void,
  setValidityReportPopup: (v: {
    show: boolean;
    data: {
      errors?: PublisherBlockValidityReportPopupProps["errors"];
      items?: PublisherBlockValidityReportPopupProps["items"];
    };
    fileURL: string;
    filename: string;
    fileId: string;
    date: string;
  }) => void
) => {
  const file = find(convertedFiles, { id: fileId });
  if (file) {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_ASA_API}/convert/validation-file/${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const data = get(response, "data.errors", []);
        const summary = get(response, "data.summary", {
          critical: 0,
          error: 0,
          warning: 0,
        });
        const errors = [
          {
            type: "Critical Error",
            count: summary.critical,
            color: colors.secondary.red,
          },
          {
            type: "Error",
            count: summary.error,
            color: colors.secondary.orange,
          },
          {
            type: "Warning",
            count: summary.warning,
            color: colors.secondary.purple,
          },
        ];
        const items: PublisherBlockValidityReportPopupProps["items"] = [];
        data.forEach((item: any, index: number) => {
          item.errors.forEach((error: any) => {
            error.errors.forEach((error1: any) => {
              switch (error1.severity) {
                case "critical":
                  errors[0].count += 1;
                  break;
                case "error":
                  errors[1].count += 1;
                  break;
                case "warning":
                  errors[2].count += 1;
                  break;
                default:
                  break;
              }
            });
          });
          items.push({
            name: item.title,
            counts: errors,
            items: [],
          });
          const severity = {
            critical: "Critical Error",
            error: "Error",
            warning: "Warning",
          };
          const severityColor = {
            critical: colors.secondary.red,
            error: colors.secondary.orange,
            warning: colors.secondary.purple,
          };
          item.errors.forEach((error: any) => {
            error.errors.forEach((error1: any) => {
              items[index].items.push({
                type: get(severity, error1.severity, error1.severity),
                name: error1.message,
                info: error1.context[0].text,
                color: get(severityColor, error1.severity, "#fff"),
              });
            });
          });
        });
        setValidityReportPopup({
          show: true,
          data: { errors, items },
          fileURL: file?.url ?? "",
          filename: file?.title ?? "",
          fileId,
          date: file?.date ?? "",
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }
};
