import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Sort from "@mui/icons-material/Sort";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import { ArrowDown } from "app/assets/vectors/jsx/arrow";
import ControlPoint from "@mui/icons-material/ControlPoint";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { SampleFiles } from "app/assets/vectors/jsx/SampleFiles";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { ROW_HEIGHT, TableProps } from "app/components/table/data";
import { DownloadIcon } from "app/assets/vectors/jsx/DownloadIcon";
import ViewColumnOutlined from "@mui/icons-material/ViewColumnOutlined";
import {
  GridRow,
  DataGrid,
  GridRowProps,
  GridToolbarProps,
  useGridApiContext,
  GridSlotsComponent,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

const CustomGridToolbar = (props: GridToolbarProps) => {
  const apiRef = useGridApiContext();

  return (
    <GridToolbarContainer
      sx={{
        justifyContent: "space-between",
        paddingBottom: props.toolBarBorderBottom ? "10px" : 0,
        borderBottom: props.toolBarBorderBottom
          ? `0.5px solid rgba(224, 224, 224, 1)`
          : "none",
      }}
    >
      <Box>
        {props.tableTitle && (
          <Typography variant="h4" fontSize={"18px"}>
            {props.tableTitle}
          </Typography>
        )}
        {props.showQuickFilter && (
          <GridToolbarQuickFilter
            variant="outlined"
            sx={{
              ".MuiInputBase-root": {
                width: "100%",
                height: "28px",
                borderRadius: "5px",
                background: colors.secondary.disableButton,
              },
              ".MuiInputBase-input": {
                fontSize: "14px",
                textAlign: "start",
                color: colors.secondary.grayText,
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderStyle: "none",
              },
              ".MuiSvgIcon-root": {
                color: colors.secondary.grayText,
              },
            }}
          />
        )}
      </Box>
      <Box>
        {props.showAddFileButton && (
          <IconButton onClick={() => props.onAddButtonClick()}>
            <ControlPoint fontSize="small" htmlColor={colors.text.title} />
          </IconButton>
        )}
        {props.showSampleFilesDownloadButton && (
          <IconButton
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_ASA_API}/file-templates/activity.zip`,
                "_blank"
              );
            }}
          >
            <SampleFiles />
          </IconButton>
        )}
        {props.showColumnsButton && (
          <IconButton>
            <ViewColumnOutlined
              fontSize="small"
              htmlColor={colors.text.title}
            />
          </IconButton>
        )}
        {props.showFilterButton && (
          <IconButton onClick={() => apiRef.current.showFilterPanel()}>
            <Sort fontSize="small" htmlColor={colors.text.title} />
          </IconButton>
        )}
        {props.showDownloadButton && (
          <IconButton onClick={() => {}}>
            <DownloadIcon />
          </IconButton>
        )}
        {props.showPrintButton && (
          <IconButton onClick={() => {}}>
            <PrintOutlinedIcon
              fontSize="medium"
              htmlColor={colors.text.title}
            />
          </IconButton>
        )}
        {props.showCollapseButton && (
          <Button
            aria-describedby={"collapse"}
            variant="contained"
            onClick={() => props.onCollapseAllButtonClick()}
            sx={{
              fontSize: "12px",
              padding: "8px 10px",
              lineHeight: "normal",
              background: colors.secondary.lightGrayText,
              marginLeft: "10px",
            }}
          >
            Collapse All
          </Button>
        )}
        {props.showSortButton && (
          <Button
            aria-describedby={"sort"}
            variant="contained"
            endIcon={<ArrowDown />}
            sx={{
              fontSize: "12px",
              padding: "8px 10px",
              lineHeight: "normal",
              marginLeft: "10px",
            }}
          >
            Sort By
          </Button>
        )}
        {props.showDeleteButton && (
          <IconButton>
            <DeleteOutline fontSize="small" />
          </IconButton>
        )}
        {props.showSearchButton && (
          <IconButton>
            <SearchIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    </GridToolbarContainer>
  );
};

const CustomGridRow = (props: GridRowProps) => {
  const detailPanelContent = React.useMemo(
    () => props?.getDetailPanelContent?.(props.row),
    [props.row, props?.getDetailPanelContent]
  );

  return (
    <React.Fragment key={props.row.id}>
      <GridRow
        {...props}
        rowHeight={
          detailPanelContent ? props.dimensions.rowHeight : props.rowHeight
        }
        key={props.row.id}
      >
        {props.children}
      </GridRow>
      {detailPanelContent ? (
        <React.Fragment>{detailPanelContent}</React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export const Table: React.FC<TableProps> = (props: TableProps) => {
  const slots: Partial<GridSlotsComponent> | undefined = React.useMemo(() => {
    let _slots: Partial<GridSlotsComponent> | undefined = {
      row: CustomGridRow,
      toolbar: CustomGridToolbar,
    };
    if (props.hideHeader) {
      _slots = {
        ..._slots,
        columnHeaders: () => null,
      };
    }
    return _slots;
  }, [props.withSearch]);

  const tableEl = React.useRef<any>();
  const [distanceBottom, setDistanceBottom] = React.useState(0);

  const scrollListener = React.useCallback(() => {
    if (!tableEl.current) return;
    const el = document.querySelector(".MuiDataGrid-scrollbar");
    // @ts-ignore
    let bottom = el?.scrollHeight - el?.clientHeight;

    if (!distanceBottom) {
      setDistanceBottom(Math.round(bottom * 0.2));
    }
    if (
      // @ts-ignore
      el?.scrollTop > bottom - distanceBottom &&
      props.hasMore &&
      !props.loading &&
      props.loadMore
    ) {
      props.loadMore();
    }
  }, [props.hasMore, props.loadMore, props.loading, distanceBottom]);

  React.useLayoutEffect(() => {
    if (!tableEl.current) return;
    const tableRef = document.querySelector(".MuiDataGrid-scrollbar");
    tableRef?.addEventListener("scroll", scrollListener);
    return () => {
      tableRef?.removeEventListener("scroll", scrollListener);
    };
  }, [scrollListener]);

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "250px",
      }}
    >
      <DataGrid
        hideFooter
        ref={tableEl}
        slots={slots}
        rows={props.rows}
        rowSelection={false}
        columnHeaderHeight={46}
        getRowHeight={props.getRowHeight}
        disableRowSelectionOnClick
        rowHeight={props.rowHeight ?? ROW_HEIGHT}
        columns={props.columns}
        loading={props.loading}
        onRowClick={props.onRowClick}
        autoHeight={props.autoHeight ?? true}
        columnVisibilityModel={{
          id: false,
          registryId: false,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: props.withSearch,
            showFilterButton: props.withFilterButton,
            showAddFileButton: props.withAddFileButton,
            showColumnsButton: props.withColumnsButton,
            showSortButton: props.withSortButton,
            showCollapseButton: props.withCollapseButton,
            showDeleteButton: props.withDeleteButton,
            showSearchButton: props.withSearchButton,
            showDownloadButton: props.withDowloadButton,
            showPrintButton: props.withPrintButton,
            showSampleFilesDownloadButton: props.withSampleFilesDownloadButton,
            toolBarBorderBottom: props.toolBarBorderBottom,
            onAddButtonClick: props.onAddButtonClick,
            onCollapseAllButtonClick: props.onCollapseAllButtonClick,
            tableTitle: props.title,
          },
          row: {
            getDetailPanelContent: props.getDetailPanelContent,
          },
        }}
        sx={{
          borderStyle: "none",
          ".MuiDataGrid-scrollbar": {
            scrollbarWidth: "none",
          },
          ".MuiDataGrid-virtualScroller": {
            height: props.rows.length < 8 ? "auto" : "650px",
            overflowY: "scroll !important",
            scrollbarWidth: "thin",
          },
          ".MuiDataGrid-columnHeaderTitle": {
            fontSize: props.headerFontSize ?? "14px",
            fontWeight: "700",
            color: colors.text.title,
          },
          ".MuiDataGrid-row": {
            ":hover": {
              cursor: props.onRowClick ? "pointer" : "default",
              background: props.onRowClick
                ? colors.secondary.lightGrey
                : "transparent",
            },
          },
          ".MuiDataGrid-cell": {
            fontSize: "12px",
            ":focus": {
              outline: "none",
            },
            ":active": {
              outline: "none",
            },
          },
        }}
      />
    </Box>
  );
};
