import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    general: true;
  }
}

export const colors = {
  primary: {
    blue: "#3B6CD3",
    green: "#008F8A",
    white: "#FFFFFF",
    black: "#000000",
  },
  secondary: {
    darkGrey: "#454545",
    grayDark: "#888888",
    lightGrey: "#f7f7f7",
    iconGray: "#A1AEBD",
    disableButton: "#EBEBEB",
    disableText: "#D7D8D9",
    red: "#ED6060",
    darkRed: "#B23D3E",
    grayText: "#626262",
    lightGrayText: "#B6B6B6",
    orange: "#FFC145",
    purple: "#6E5ACC",
    blue: "#3C6DD3",
    grey: "#373D43",
  },
  shades: {
    blue: {
      100: "#ebf0fb",
      200: "#9db6e9",
      400: "#294c94",
      500: "#182b54",
      600: "#0c162a",
    },
    green: {
      100: "#e9f5f4",
      200: "#66bcb9",
      400: "#00726e",
      500: "#004845",
      600: "#001d1c",
    },
  },
  charts: [
    "#2A1FF9",
    "#0026FF",
    "#0070CC",
    "#0086B3",
    "#009199",
    "#00806C",
    "#004D22",
    "#00330D",
    "#006642",
    "#009658",
    "#00BA2D",
    "#00D40D",
    "#C89105",
  ],
  shadows: {
    main: "0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
    inner: "0px 3px 3px 0px rgba(0, 0, 0, 0.1) inset",
    section:
      "0px 1.446765661239624px 2.893531322479248px 0px rgba(97, 97, 97, 0.2)",
  },
  text: {
    title: "#0C162A",
    body: "#454545",
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary.blue,
    },
    secondary: {
      main: colors.primary.green,
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    h1: {
      fontSize: "80px",
      fontWeight: "700",
      color: colors.text.title,
    },
    h2: {
      fontSize: "60px",
      fontWeight: "700",
      color: colors.text.title,
    },
    h3: {
      fontSize: "40px",
      fontWeight: "700",
      color: colors.text.title,
    },
    h4: {
      fontSize: "32px",
      fontWeight: "700",
      color: colors.text.title,
    },
    h5: {
      fontSize: "24px",
      fontWeight: "700",
      color: colors.text.title,
    },
    h6: {
      fontSize: "18px",
      fontWeight: "700",
      color: colors.text.title,
    },
    subtitle1: {
      fontSize: "16px",
      fontWeight: "700",
      color: colors.text.title,
    },
    subtitle2: {
      fontSize: "14px",
      fontWeight: "400",
      color: colors.text.body,
    },
    body1: {
      fontSize: "16px",
      fontWeight: "400",
      color: colors.text.body,
    },
    body2: {
      fontSize: "14px",
      fontWeight: "400",
      color: colors.text.body,
    },
    button: {
      fontSize: "14px",
      fontWeight: "700",
      textTransform: "none",
      color: colors.text.title,
    },
    overline: {
      fontSize: "12px",
      fontWeight: "400",
      textTransform: "none",
      color: colors.text.body,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          background: colors.secondary.lightGrey,
          borderBottom: `1px solid ${colors.secondary.iconGray}`,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: "50px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          background: colors.primary.white,
          "@media (min-width: 920px)": {
            padding: 0,
            minHeight: "50px",
          },
          "@media (max-width: 1280px)": {
            width: "100vw",
          },
          "@media (max-width: 920px)": {
            padding: "0 48px",
          },
          "@media (max-width: 768px)": {
            padding: "0 24px",
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
          boxShadow: "none",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          width: "100%",
          borderRadius: 0,
          boxShadow: "none",
          borderBottom: "1px solid #CFD4DA",
          "&:before": {
            display: "none",
          },
          "&.Mui-expanded": {
            margin: 0,
            paddingBottom: "10px",
          },
          "&:last-of-type": {
            borderBottomStyle: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          minHeight: "40px",
          maxHeight: "40px",
          flexDirection: "row",
          "&.Mui-expanded": {
            minHeight: "40px",
            maxHeight: "40px",
          },
        },
        content: {
          margin: 0,
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0,
          display: "flex",
          ".MuiGrid-container": {
            width: "calc(100% - 50px)",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          padding: "4px 0",
          // userSelect: "none",
          alignItems: "flex-start",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#000 !important",
          padding: "0 4px 0 0",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          "*": {
            borderColor: colors.secondary.lightGrey,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          padding: "10px 16px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "10px 16px",
        },
        head: {
          cursor: "pointer",
          fontWeight: "700",
          textWrap: "nowrap",
          borderStyle: "none",
          position: "relative",
          color: colors.primary.blue,
        },
        body: {
          color: colors.primary.blue,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          display: "flex",
          borderStyle: "none",
          padding: "10px 16px",
          justifyContent: "flex-start",
        },
        toolbar: {
          marginLeft: 0,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "general" },
          style: {
            fontSize: "12px",
            fontWeight: "700",
            textTransform: "none",
            color: colors.primary.white,
            background: colors.primary.blue,
            padding: "8px 10px",
            lineHeight: "normal",
            ":hover": {
              background: colors.shades.blue[400],
            },
            ":disabled": {
              color: colors.primary.white,
              background: colors.secondary.lightGrayText,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "5px",
          padding: "6px 10px",
          color: colors.primary.white,
          ":disabled": {
            color: colors.primary.white,
            background: colors.secondary.lightGrayText,
          },
        },
        outlined: {
          fontWeight: "400",
          padding: "2px 12px",
          textTransform: "none",
          borderColor: "#DFE3E5",
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: colors.secondary.lightGrey,
        },
        tooltip: {
          padding: "10px",
          fontSize: "12px",
          fontWeight: "400",
          fontFamily: "Inter",
          borderRadius: "4px",
          color: colors.primary.black,
          background: colors.secondary.lightGrey,
          boxShadow: "0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
          border: `1px solid ${colors.secondary.lightGrayText}`,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: colors.text.body,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "5px",
          background: colors.secondary.lightGrey,
        },
        list: {
          padding: "0",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          fontWeight: "700",
          padding: "4px 8px",
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        colorPrimary: {
          color: colors.text.body,
        },
      },
    },
  },
});

export default theme;
