import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { FileRejection } from "react-dropzone";
import Typography from "@mui/material/Typography";
import { Link as NavLink } from "react-router-dom";
import { ReactComponent as Astronaut } from "app/assets/vectors/PublisherAstronaut.svg";
import { PublisherBlockValidityReportPopupProps } from "app/pages/publisher/components/publisher-block/views/validityReportPopup";
import {
  ConvertedFile,
  ConvertedFileActivity,
} from "app/components/table/data";

interface FileValidationErrorContext {
  text: string;
}

export interface FileValidationError {
  id: string;
  codelist?: string;
  context: FileValidationErrorContext[];
  message: string;
  severity: string;
}

export interface FileValidationActivityError {
  category: string;
  errors: FileValidationError[];
}

export interface FileValidationActivity {
  identifier: string;
  title: string;
  errors: FileValidationActivityError[];
  summary: FileValidationSummary;
}

export interface FileValidationSummary {
  critical: number;
  error: number;
  warning: number;
}

export interface FileValidationReportLayoutProps {
  orgRef: string;
  uuid: string;
  filename: string;
  date: Date | null;
  activities: FileValidationActivity[];
  summary: FileValidationSummary;
  categories: string[];
  activeFilter: string;
  setActiveFilter: (filter: string) => void;
  loading: boolean;
  errorMsg: string;
}

export interface FileValidationHeaderProps {
  orgRef: string;
  uuid: string;
  filename: string;
  date: Date | null;
  summary: FileValidationSummary;
  loading: boolean;
  errorMsg: string;
}

export interface FileValidationListProps {
  activeFilter: string;
  activities: FileValidationActivity[];
}

export interface FileValidationListItemProps extends FileValidationActivity {
  forceExpand: boolean;
  activeFilter: string;
}

export interface FileValidationFiltersProps {
  filters: string[];
  activeFilter: string;
  setActiveFilter: (filter: string) => void;
}

export interface PublisherBlockLayoutProps {
  handlePreVizPopupOpenChange: (value: boolean) => () => void;
  onBackClick: () => void;
  onNextClick: () => void;
  isBackActive: boolean;
  isNextActive: boolean;
  nextButtonPopupOpen: boolean;
  setNextButtonPopupOpen: (value: boolean) => void;
  clear: () => void;
  filename: string;
  onToolbarButtonClick: () => void;
  uploading: boolean;
  setFilename: (value: string) => void;
  uploadViewMessage: string;
  selectedFiles: File[];
  setSelectedFiles: (value: File[]) => void;
  rejectedFiles: FileRejection[];
  setRejectedFiles: (value: FileRejection[]) => void;
  convertedFileActivities: ConvertedFileActivity[];
  handleValidityReportOpenChange: (value: string | null) => void;
  convertedFiles: ConvertedFile[];
  handleConvertedFilePublishStatusChange: (
    fileId: string,
    value: boolean,
    isValid: boolean,
    registryFileId?: string
  ) => void;
  preVizPopupOpen: boolean;
  fileURL: string;
  reportData: {
    errors?: PublisherBlockValidityReportPopupProps["errors"];
    items?: PublisherBlockValidityReportPopupProps["items"];
  };
  validityReportPopupOpen: string | null;
  modal: {
    open: boolean;
    title: string;
    message: React.ReactNode;
  };
  setModal: (value: {
    open: boolean;
    title: string;
    message: React.ReactNode;
  }) => void;
  resetModal: () => void;
  activeStep: number;
  setActiveStep: (value: number) => void;
}

export const PublisherErrorMessageModal: React.FC<{
  open: boolean;
  title: string;
  close: () => void;
  message: string | React.ReactNode;
}> = (props) => {
  return (
    <Modal
      hideBackdrop
      disablePortal
      open={props.open}
      disableEscapeKeyDown
      onClose={props.close}
    >
      <Box
        sx={{
          gap: "20px",
          display: "flex",
          padding: "20px",
          maxWidth: "700px",
          flexDirection: "row",
          background: colors.primary.white,
          boxShadow: "0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
        }}
      >
        <Box>
          <Astronaut />
        </Box>
        <Box
          sx={{
            gap: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            fontSize="18px"
            fontWeight="700"
            color={colors.shades.green[500]}
          >
            {props.title}
          </Typography>
          <Divider />
          <Typography fontSize="14px" color={colors.text.title}>
            {props.message}
          </Typography>
          <Box
            sx={{
              gap: "10px",
              display: "flex",
              paddingTop: "10px",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Link
              target="_blank"
              to="/user-guide"
              component={NavLink}
              sx={{
                fontSize: "14px",
                color: colors.text.title,
              }}
            >
              Need Further Guidance? See the User Guide.
            </Link>
            <Button
              color="secondary"
              variant="contained"
              onClick={props.close}
              sx={{
                padding: "4px 10px",
              }}
            >
              I understand
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
