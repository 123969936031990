import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

export const ExplorerHomeSDGs: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/sdgs/{transactionTypeCode}/{period}`
  ),
};

export const ExplorerSDGs: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/sdgs/{transactionTypeCode}/{period}`
  ),
};
