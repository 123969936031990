import React from "react";
import axios from "axios";
import get from "lodash/get";
import cryptoJS from "crypto-js";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { useAuth0 } from "@auth0/auth0-react";
import Typography from "@mui/material/Typography";
import FilledInput from "@mui/material/FilledInput";
import Info from "@mui/icons-material/InfoOutlined";
import { Link as RouterLink } from "react-router-dom";
import { PageLoader } from "app/components/page-loader";
import { InfoTooltip } from "app/components/info-tooltip";
import useUpdateEffect from "react-use/lib/useUpdateEffect";
import useLocalStorage from "react-use/lib/useLocalStorage";
import ProfilePicture from "app/assets/images/profile-picture.png";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { socialAuth, SocialAuthConnectionType } from "app/utils/socialAuth";
import { SignInMethods } from "app/components/header/sub-components/expanded-view/views/signin";
import { ReactComponent as CheckMark } from "app/assets/vectors/PublisherIATIConnectCheckMarkGreen.svg";
import { ReactComponent as ExlamationMarkGrey } from "app/assets/vectors/PublisherIATIConnectExclamationMarkGrey.svg";
import { ReactComponent as ExlamationMarkGreen } from "app/assets/vectors/PublisherIATIConnectExclamationMarkGreen.svg";

interface PublisherBlockConnectToIATIViewProps {
  setNextButtonPopupOpen: (value: boolean) => void;
}

export const PublisherBlockConnectToIATIView: React.FC<
  PublisherBlockConnectToIATIViewProps
> = (props: PublisherBlockConnectToIATIViewProps) => {
  const { user, isLoading, isAuthenticated, getAccessTokenSilently } =
    useAuth0();

  const calledRef = React.useRef(false);

  const storedUser = useStoreState((state) => get(state.user, "data.data", {}));
  const fetchUser = useStoreActions((actions) => actions.user.authGetFetch);
  const fetchPublisherConvertedFiles = useStoreActions(
    (actions) => actions.PublisherConvertedFiles.authGetFetch
  );
  const loadingFetchUser = useStoreState((state) => state.user.loading);

  const [publisherId, setPublisherId] = React.useState("");
  const [registrationIdentifier, setRegistrationIdentifier] =
    React.useState("");
  const [apiToken, setApiToken] = React.useState("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [token, setToken] = React.useState<string | null>(null);

  const setAuthRedirectData = useLocalStorage("auth-redirect-data", {
    step: -1,
    pathname: "/",
  })[1];
  const [fileASAWorkspaceDir, _, clearFileASAWorkspaceDir] = useLocalStorage(
    "publisher-non-signed-in-user-file-asa-workspace-dir",
    ""
  );
  const [fileDetails, __, clearFileDetails] = useLocalStorage(
    "publisher-non-signed-in-user-file-details",
    {
      filename: "",
      orgRef: "",
      type: "",
    }
  );

  const getAccessTokenSilentlyWithState = async () => {
    if (isAuthenticated && !token) {
      const newToken = await getAccessTokenSilently();
      setToken(newToken);
      return newToken;
    }
    return token;
  };

  const handleSignInButtonClick = (type: SocialAuthConnectionType) => () => {
    setAuthRedirectData({ step: 2, pathname: "/publisher" });
    socialAuth(type);
  };

  const handlePublisherIdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPublisherId(event.target.value);
  };

  const handleRetrievePublisherInfo = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API}/publisher/${publisherId}`)
      .then(async (response) => {
        setRegistrationIdentifier(response.data.publisher_iati_id);
        const t = await getAccessTokenSilentlyWithState();
        await axios
          .put(
            `${process.env.REACT_APP_API}/user/me`,
            {
              app_metadata: {
                publisherID: publisherId,
                publisherInfo: {
                  title: response.data.title,
                  publisher_iati_id: response.data.publisher_iati_id,
                  publisher_organization_type:
                    response.data.publisher_organization_type,
                },
                registryApiKey: apiToken,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${t}`,
              },
            }
          )
          .then(() => {
            setLoading(false);
            fetchUser({ values: { token: t } });
            console.log("User updated successfully");
          })
          .catch((error) => {
            setLoading(false);
            console.error(`Error updating user: ${error}`);
          });
      })
      .catch((error) => {
        setLoading(false);
        console.error(`Error fetching publisher: ${error}`);
        setRegistrationIdentifier("");
      });
  };

  const handleApiTokenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApiToken(event.target.value);
  };

  const handleSaveApiToken = async () => {
    setLoading(true);
    const t = await getAccessTokenSilentlyWithState();
    await axios
      .put(
        `${process.env.REACT_APP_API}/user/me`,
        {
          app_metadata: {
            registryApiKey: apiToken,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${t}`,
          },
        }
      )
      .then(() => {
        setLoading(false);
        fetchUser({ values: { token: t } });
        console.log("User updated successfully");
      })
      .catch((error) => {
        setLoading(false);
        console.error(`Error updating user: ${error}`);
      });
  };

  // call ASA API to upload file from ASA workspace to spaces & create a new converted file instance
  const moveWorkspaceFileToStorage = async () => {
    calledRef.current = true;
    if (
      isAuthenticated &&
      fileASAWorkspaceDir &&
      fileDetails &&
      fileDetails.filename
    ) {
      const t = await getAccessTokenSilentlyWithState();
      const encryptedValues = cryptoJS.AES.encrypt(
        JSON.stringify({
          ...fileDetails,
          email: user?.email,
          workspaceDir: fileASAWorkspaceDir,
        }),
        process.env.REACT_APP_ENCRYPTION_SECRET as string
      ).toString();
      axios
        .get(
          `${process.env.REACT_APP_ASA_API}/convert/move-workspace-file-to-storage`,
          {
            params: {
              payload: encryptedValues,
            },
            headers: {
              Authorization: `Bearer ${t}`,
            },
          }
        )
        .then(() => {
          console.log("File moved to storage successfully");
          fetchPublisherConvertedFiles({
            values: { token: t, orgRef: fileDetails.orgRef },
          });
          clearFileASAWorkspaceDir();
          clearFileDetails();
        })
        .catch((error) => {
          console.error(`Error moving file to storage: ${error}`);
        });
    }
  };

  React.useEffect(() => {
    if (isAuthenticated && publisherId && registrationIdentifier) {
      props.setNextButtonPopupOpen(false);
    } else {
      props.setNextButtonPopupOpen(true);
    }
  }, [isAuthenticated, publisherId, registrationIdentifier]);

  React.useEffect(() => {
    if (isAuthenticated) {
      setPublisherId(get(storedUser, "app_metadata.publisherID", ""));
      setRegistrationIdentifier(
        get(storedUser, "app_metadata.publisherInfo.publisher_iati_id", "")
      );
      setApiToken(get(storedUser, "app_metadata.registryApiKey", ""));
    }
  }, [isAuthenticated, storedUser]);

  useUpdateEffect(() => {
    if (!calledRef.current) moveWorkspaceFileToStorage();
  }, [isAuthenticated]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "450px",
        display: "flex",
        padding: "15px 0",
        flexDirection: "column",
        a: {
          color: "inherit",
        },
      }}
    >
      {(isLoading || loading || loadingFetchUser) && <PageLoader />}
      <Box
        sx={{
          gap: "10px",
          width: "100%",
          display: "flex",
          padding: "15px",
          flexDirection: "row",
          alignItems: "flex-start",
          background: colors.secondary.lightGrey,
        }}
      >
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.2952 6.07135C3.43054 4.90444 4.13849 3.94109 5.28551 3.68743C7.07466 3.29175 10.5426 2.83203 16.9987 2.83203C23.4548 2.83203 26.9227 3.29175 28.7119 3.68743C29.8589 3.9411 30.5668 4.90444 30.7022 6.07135C30.9069 7.83598 31.1654 10.7422 31.1654 14.1654C31.1654 20.5605 27.7902 26.5445 22.1039 29.471C20.2045 30.4485 18.3507 31.1654 16.9987 31.1654C15.6467 31.1654 13.7929 30.4485 11.8934 29.471C6.20719 26.5445 2.83203 20.5605 2.83203 14.1654C2.83203 10.7422 3.09052 7.83598 3.2952 6.07135Z"
            fill="#66BCB9"
          />
          <path
            d="M15.4282 7.29163C14.5772 7.53451 14.1977 8.40739 14.2558 9.29047C14.3896 11.3266 14.6598 15.0833 14.9511 17.1001C15.0373 17.6967 15.3833 18.2176 15.9757 18.3293C16.2423 18.3796 16.58 18.4154 17.0012 18.4154C17.4224 18.4154 17.7601 18.3796 18.0267 18.3293C18.6191 18.2176 18.9651 17.6967 19.0513 17.1001C19.3426 15.0833 19.6128 11.3266 19.7466 9.29047C19.8047 8.40739 19.4252 7.53451 18.5742 7.29163C18.1547 7.17185 17.6303 7.08203 17.0012 7.08203C16.3721 7.08203 15.8478 7.17185 15.4282 7.29163Z"
            fill="#004845"
          />
          <path
            d="M17.0013 26.2096C18.5661 26.2096 19.8346 24.9411 19.8346 23.3763C19.8346 21.8115 18.5661 20.543 17.0013 20.543C15.4365 20.543 14.168 21.8115 14.168 23.3763C14.168 24.9411 15.4365 26.2096 17.0013 26.2096Z"
            fill="#004845"
          />
        </svg>
        <Box
          sx={{
            gap: "5px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5" fontSize="14px">
            Your registration to IATI will take 24-hours to get verified. Till
            then, your data will be stored as Draft.
          </Typography>
          <Box>
            <Typography
              fontSize="12px"
              component="div"
              sx={{
                "> span": {
                  fontWeight: "700",
                },
              }}
            >
              You can publish your files to IATI from Your Data Hub once you get
              verified.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          gap: "20px",
          display: "flex",
          paddingTop: "20px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            gap: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {isAuthenticated ? <CheckMark /> : <ExlamationMarkGreen />}
          <Typography fontSize="12px">Sign In</Typography>
        </Box>
        <Box
          display="flex"
          minHeight="64px"
          paddingLeft="40px"
          alignItems="center"
        >
          {!isAuthenticated && (
            <Box
              sx={{
                gap: "30px",
                display: "flex",
                flexDirection: "row",
                "> button": {
                  gap: "10px",
                  width: "190px",
                  height: "32px",
                  display: "flex",
                  fontSize: "12px",
                  fontWeight: "700",
                  borderRadius: "5px",
                  alignItems: "center",
                  textTransform: "none",
                  justifyContent: "center",
                  color: colors.primary.white,
                  background: colors.primary.green,
                  ":hover": {
                    background: colors.shades.green[400],
                  },
                  svg: {
                    "> path": {
                      fill: colors.primary.green,
                    },
                    "> g > path": {
                      stroke: colors.primary.green,
                    },
                  },
                },
              }}
            >
              {SignInMethods.map((method) => (
                <Button
                  startIcon={method.icon}
                  key={method.title}
                  onClick={handleSignInButtonClick(method.type)}
                >
                  {method.title}
                </Button>
              ))}
            </Box>
          )}
          {isAuthenticated && (
            <Box
              sx={{
                gap: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                "> img": {
                  width: "62px",
                  height: "62px",
                  borderRadius: "50%",
                  border: `1px solid ${colors.secondary.iconGray}`,
                },
              }}
            >
              <img src={storedUser.picture ?? ProfilePicture} alt="Profile" />
              <Box
                sx={{
                  gap: "5px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h5" fontSize="14px">
                  Hello, {get(storedUser, "name", "-").split(" ")[0]}. You are
                  signed in.
                </Typography>
                <Box
                  sx={{
                    padding: "5px",
                    fontSize: "12px",
                    fontStyle: "italic",
                    borderRadius: "5px",
                    background: colors.shades.green[100],
                    border: `1px solid ${colors.shades.green[400]}`,
                  }}
                >
                  You can edit your profile settings and information anytime
                  from{" "}
                  <Link component={RouterLink} to="/account-profile">
                    My Account
                  </Link>
                  .
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            gap: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {isAuthenticated ? <ExlamationMarkGreen /> : <ExlamationMarkGrey />}
          <Typography fontSize="12px">Connect to IATI Registry</Typography>
        </Box>
        <Box
          sx={{
            gap: "15px",
            display: "flex",
            paddingTop: "20px",
            paddingLeft: "40px",
            flexDirection: "column",
            "> div": {
              gap: "10px",
              display: "flex",
              alignItems: "center",
              "> div:first-of-type": {
                width: "250px",
                borderRadius: "5px",
                border: `1px solid ${colors.secondary.iconGray}`,
                input: {
                  fontSize: "12px",
                  padding: "8px 20px",
                },
              },
              a: {
                color: colors.primary.blue,
              },
            },
          }}
        >
          <Box>
            <FilledInput
              disableUnderline
              value={publisherId}
              disabled={!isAuthenticated}
              placeholder="Publisher ID *"
              onChange={handlePublisherIdChange}
            />
            <InfoTooltip arrow mode="dark" info="IATI Registry Publisher ID">
              <Info htmlColor={colors.secondary.iconGray} />
            </InfoTooltip>
            {((publisherId && !registrationIdentifier) ||
              publisherId !==
                get(storedUser, "app_metadata.publisherID", "")) && (
              <Button
                color="secondary"
                variant="contained"
                disabled={!isAuthenticated}
                onClick={handleRetrievePublisherInfo}
              >
                Retrieve Publisher info
              </Button>
            )}
          </Box>
          <Box>
            <FilledInput
              disabled
              disableUnderline
              value={registrationIdentifier}
              placeholder="IATI Registration Identifier *"
            />
            <InfoTooltip
              arrow
              mode="dark"
              info="IATI Registry Publisher Reference"
            >
              <Info htmlColor={colors.secondary.iconGray} />
            </InfoTooltip>
            <Typography fontSize="12px" component="div">
              Not yet registered? Go{" "}
              <Link href="https://iatiregistry.org/" target="_blank">
                here
              </Link>
            </Typography>
          </Box>
          <Box>
            <FilledInput
              type="password"
              disableUnderline
              value={apiToken}
              placeholder="API Token"
              disabled={!isAuthenticated}
              onChange={handleApiTokenChange}
            />
            <InfoTooltip
              arrow
              mode="dark"
              info="IATI Registry Publisher API token"
            >
              <Info htmlColor={colors.secondary.iconGray} />
            </InfoTooltip>
            <Typography fontSize="12px" component="div">
              Learn how to find or generate your API key{" "}
              <Link href="https://iatiregistry.org/" target="_blank">
                here
              </Link>
            </Typography>
          </Box>
          {apiToken &&
            apiToken !== get(storedUser, "app_metadata.registryApiKey", "") && (
              <Button
                color="secondary"
                variant="contained"
                sx={{ width: "250px" }}
                disabled={!isAuthenticated}
                onClick={handleSaveApiToken}
              >
                Save API Token
              </Button>
            )}
        </Box>
      </Box>
    </Box>
  );
};
