import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Close from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Info from "@mui/icons-material/InfoOutlined";
import { InfoTooltip } from "app/components/info-tooltip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as DownloadIcon } from "app/assets/vectors/PublisherValidityReportDownload.svg";

export interface PublisherBlockValidityReportPopupProps {
  date: string;
  open: boolean;
  title: string;
  fileURL: string;
  fileName: string;
  onClose: () => void;
  value: string | null;
  errors?: {
    type: string;
    count: number;
    color: string;
  }[];
  items?: {
    name: string;
    counts: {
      type: string;
      count: number;
      color: string;
    }[];
    items: {
      type: string;
      name: string;
      info: string;
      color: string;
    }[];
  }[];
}

export const PublisherBlockValidityReportPopup: React.FC<
  PublisherBlockValidityReportPopupProps
> = (props: PublisherBlockValidityReportPopupProps) => {
  return (
    <Modal
      keepMounted
      open={props.open}
      closeAfterTransition
      onClose={props.onClose}
      sx={{
        paddingTop: "100px",
        paddingBottom: "100px",
        alignItems: "flex-start",
      }}
    >
      <Fade in={props.open}>
        <Box
          sx={{
            gap: "10px",
            width: "100%",
            display: "flex",
            overflowY: "auto",
            maxWidth: "1200px",
            borderRadius: "8px",
            flexDirection: "column",
            height: "calc(100vh - 200px)",
            padding: "20px 40px 40px 40px",
            background: colors.primary.white,
            boxShadow: "0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
            ".MuiAccordion-root.Mui-expanded": {
              paddingBottom: 0,
            },
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingBottom: "20px",
                  justifyContent: "space-between",
                  borderBottom: `1px solid ${colors.secondary.iconGray}`,
                }}
              >
                <Box
                  sx={{
                    gap: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    fontSize="12px"
                    color={colors.text.title}
                    textTransform="uppercase"
                  >
                    Validity Report
                  </Typography>
                  <Typography variant="h4" fontSize="18px">
                    {props.title}
                  </Typography>
                </Box>
                <IconButton onClick={props.onClose}>
                  <Close htmlColor={colors.secondary.iconGray} />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  gap: "10px",
                  display: "flex",
                  padding: "10px 0",
                  flexDirection: "column",
                  borderRight: `1px solid ${colors.secondary.iconGray}`,
                }}
              >
                <Typography fontSize="12px" color={colors.text.title}>
                  Found
                </Typography>
                {props.errors?.map((error) => (
                  <Box
                    key={error.type}
                    sx={{
                      gap: "10px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        fontSize: "16px",
                        fontWeight: "700",
                        borderRadius: "50%",
                        alignItems: "center",
                        background: error.color,
                        justifyContent: "center",
                        color: colors.primary.white,
                      }}
                    >
                      {error.count}
                    </Box>
                    <Typography
                      variant="h4"
                      fontSize="18px"
                      color={colors.text.title}
                    >
                      {error.type}
                    </Typography>
                    <InfoTooltip arrow mode="dark" info={error.type}>
                      <Info
                        fontSize="small"
                        htmlColor={colors.secondary.darkGrey}
                      />
                    </InfoTooltip>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item container xs={12} sm={6}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    paddingLeft: "10px",
                    paddingBottom: "10px",
                    flexDirection: "column",
                    borderBottom: `1px solid ${colors.secondary.iconGray}`,
                  }}
                >
                  <Typography fontSize="12px" color={colors.text.title}>
                    Report XML
                  </Typography>
                  <Box
                    sx={{
                      gap: "10px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      href={props.fileURL}
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        color: colors.text.title,
                      }}
                    >
                      {props.fileName}.xml
                    </Link>
                    <IconButton>
                      <DownloadIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    gap: "10px",
                    display: "flex",
                    paddingTop: "20px",
                    paddingLeft: "10px",
                    flexDirection: "column",
                  }}
                >
                  <Typography fontSize="12px" color={colors.text.title}>
                    Generated on
                  </Typography>
                  <Typography
                    variant="h4"
                    fontSize="18px"
                    color={colors.text.title}
                  >
                    {props.date
                      ? new Date(props.date).toLocaleDateString(undefined, {
                          weekday: undefined,
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          timeZoneName: "short",
                          timeZone: "Europe/Amsterdam",
                        })
                      : ""}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              gap: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {props.items?.map((file) => (
              <Accordion
                key={file.name}
                sx={{
                  ".MuiAccordionSummary-root": {
                    minHeight: "37px",
                    padding: "4px 10px",
                    background: colors.secondary.disableButton,
                    border: `1px solid ${colors.secondary.iconGray}`,
                  },
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h5" fontSize="14px">
                      {file.name}
                    </Typography>
                    <Box
                      sx={{
                        gap: "10px",
                        display: "flex",
                        flexDirection: "row",
                        "> div": {
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          fontSize: "16px",
                          fontWeight: "700",
                          borderRadius: "50%",
                          alignItems: "center",
                          justifyContent: "center",
                          color: colors.primary.white,
                        },
                      }}
                    >
                      {file.counts.map((count) => (
                        <Box key={count.type} bgcolor={count.color}>
                          {count.count}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      "> div": {
                        width: "100%",
                        display: "flex",
                        minHeight: "28px",
                        flexDirection: "row",
                        borderRight: `1px solid ${colors.secondary.iconGray}`,
                        borderBottom: `1px solid ${colors.secondary.iconGray}`,
                        "> p": {
                          padding: "4px 20px",
                          ":nth-child(1)": {
                            width: "80%",
                          },
                          ":nth-child(2)": {
                            width: "20%",
                            borderLeft: `1px solid ${colors.secondary.iconGray}`,
                          },
                        },
                      },
                    }}
                  >
                    {file.items.map((item) => (
                      <Box
                        sx={{
                          borderLeft: `7px solid ${item.color}`,
                        }}
                      >
                        <Typography fontSize="12px">{item.name}</Typography>
                        <Typography fontSize="12px">{item.info}</Typography>
                      </Box>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
