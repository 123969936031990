import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { Table } from "app/components/table";
import Typography from "@mui/material/Typography";
import { useFavouritesUtils } from "app/hooks/useFavouritesUtils";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { LiveDataUpdatesProps } from "app/pages/home/explorer/components/live-data-updates/data";
import { getActivitiesColumns } from "app/pages/home/explorer/components/live-data-updates/tableColumns";

export const LiveDataUpdates: React.FC<LiveDataUpdatesProps> = (
  props: LiveDataUpdatesProps
) => {
  const { favourites, onFavouriteButtonClick } = useFavouritesUtils();

  const columns = React.useMemo(() => {
    const cols = getActivitiesColumns(favourites, onFavouriteButtonClick);
    return cols;
  }, [favourites]);

  return (
    <React.Fragment>
      <Typography variant="h3" fontSize="36px">
        Live Data Updates
      </Typography>
      <Box height={20} />
      <GenericPageBlockItem
        title="RECENT ACTIVITIES"
        description={
          <React.Fragment>
            There's currently{" "}
            <span style={{ color: colors.primary.blue }}>
              {props.activities.length} recent activities{" "}
            </span>
            in IATI dataset that have been uploaded in the last 48 hours.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Table
              withSearch
              headerFontSize="12px"
              rows={props.activities}
              columns={columns}
            />
          </React.Fragment>
        }
        toolbarShare
      />
    </React.Fragment>
  );
};
