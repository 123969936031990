import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { Table } from "app/components/table";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { PageHeader } from "app/components/page-header";
import { useFavouritesUtils } from "app/hooks/useFavouritesUtils";
import { FavouriteIcon } from "app/assets/vectors/jsx/FavouriteIcon";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { getFavouritesColumns } from "app/pages/explorer/pages/favourites/tableColums";
import { ReactComponent as ExplorerFavouritesHeaderIcon } from "app/assets/vectors/ExplorerFavouritesHeaderIcon.svg";

const Empty: React.FC = () => (
  <Box
    sx={{
      display: "flex",
      columnGap: "30px",
      alignItems: "center",
      padding: "50px 100px",
      justifyContent: "flex-start",
      height: "calc(100vh - 628px)",
      background: colors.primary.white,
    }}
  >
    <FavouriteIcon height={67} width={67} />
    <Box>
      <Typography variant="h2" fontSize="36px">
        Add favourite activities for future reference!
      </Typography>
      <Typography
        variant="h5"
        fontSize="14px"
        fontWeight={400}
        lineHeight="20px"
      >
        Start marking activities now to follow the recent updates & archive your
        interests.
      </Typography>
    </Box>
  </Box>
);

export const ExplorerFavourites: React.FC = () => {
  const navigate = useNavigate();
  const { count, loading, favourites, removeFavourite } = useFavouritesUtils();

  const onTableRowClick = (params: any) => {
    navigate(`/explorer/activities/${params.row.itemId}`);
  };

  return (
    <Box>
      <PageHeader
        variant="explorer"
        title="Your Favourites: Aid Insights, At Your Fingertips"
        subtitle="Introducing 'Your Bookmarks' - your personalized area for humanitarian insights. Save and organize your favorite activities for quick access. This feature is designed with you in mind, making it easier to revisit critical information."
        icon={<ExplorerFavouritesHeaderIcon />}
      />
      <Box height={40} />
      {count > 0 && !loading ? (
        <GenericPageBlockItem
          title="Your favourites"
          description={
            <React.Fragment>
              You have favoured{" "}
              <span style={{ color: colors.primary.blue }}>
                {count} data assets.
              </span>
            </React.Fragment>
          }
          content={
            <React.Fragment>
              <Table
                withSearch
                loading={loading}
                rows={favourites}
                headerFontSize="12px"
                onRowClick={onTableRowClick}
                columns={getFavouritesColumns(removeFavourite)}
              />
            </React.Fragment>
          }
        />
      ) : (
        <Empty />
      )}
    </Box>
  );
};
