import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Table } from "app/components/table";
import Typography from "@mui/material/Typography";
import { PieChart } from "app/components/charts/pie";
import { formatNumber } from "app/utils/formatNumber";
import { useStoreState } from "app/state/store/hooks";
import { ImpactChart } from "app/components/charts/impact";
import { GeomapChart } from "app/components/charts/geomap";
import { useExpandedBlock } from "app/hooks/useExpandedBlock";
import { ScrollIntoView } from "app/components/scroll-into-view";
import { ChartPlaceholder } from "app/components/chart-placeholder";
import { OverviewBlock } from "app/components/grid-blocks/overview";
import { LocationsBlock } from "app/components/grid-blocks/locations";
import { TARGET_COLUMNS } from "app/components/charts/impact/tableData";
import { GenericPageBlockItem } from "app/components/generic-page-block-item";
import { KeyProjectionsBlockProps } from "app/pages/explorer/components/key-projections-block/data";
import {
  IMPACT_CHART_ITEMS,
  IMPACT_CHART_ITEMS_EXTRA,
} from "app/components/charts/impact/data";

export const KeyProjectionsBlock: React.FC<KeyProjectionsBlockProps> = (
  props: KeyProjectionsBlockProps
) => {
  const { expandedBlock, handleSetExpandedBlock } = useExpandedBlock({
    blockIds: [
      "key-locations",
      "key-organisations",
      "key-sectors",
      "key-targets",
    ],
  });

  const overviewStats: {
    total: number;
    activityCount: number;
    orgCount: number;
    countryCount: number;
  } = useStoreState((state) =>
    get(state.ExplorerProjectionsOverviewStats.data, "data", {
      total: 0,
      activityCount: 0,
      orgCount: 0,
      countryCount: 0,
    })
  );
  const overviewStatsLoading = useStoreState(
    (state) => state.ExplorerProjectionsOverviewStats.loading
  );
  const overview = React.useMemo(() => {
    const items = [
      {
        name: props.transactionType.label,
        value: `USD ${formatNumber(overviewStats.total, true)}`,
      },
      { name: "Activities", value: overviewStats.activityCount.toString() },
      { name: "Organisations", value: overviewStats.orgCount.toString() },
      {
        name: "Recipient Countries",
        value: overviewStats.countryCount.toString(),
      },
    ];

    return (
      <GenericPageBlockItem
        toolbarShare
        loading={overviewStatsLoading}
        title={`${props.period} Overview`}
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              USD {formatNumber(overviewStats.total, true)}
            </span>{" "}
            is set to be spent for
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {" "}
              92 recipient countries
            </span>{" "}
            in {props.period}.
          </React.Fragment>
        }
        content={<OverviewBlock items={items} />}
      />
    );
  }, [
    props.period,
    overviewStats,
    overviewStatsLoading,
    props.transactionType,
  ]);

  const locationsData: {
    stats: {
      code: string;
      name: string;
      value: number;
      count: number;
    }[];
    geo_data: {
      name: string;
      iso2: string;
      value: number;
      full_name: string;
    }[];
  } = useStoreState((state) =>
    get(state.ExplorerProjectionsLocations.data, "data", {
      stats: [],
      geo_data: [],
    })
  );
  const locationsLoading = useStoreState(
    (state) => state.ExplorerProjectionsLocations.loading
  );
  const locations = React.useMemo(() => {
    const items = locationsData.stats.map((item) => ({
      code: item.code.toLowerCase(),
      name: item.name,
      funds: `USD ${formatNumber(item.value, true)}`,
      activities: item.count,
    }));

    return (
      <React.Fragment>
        <GenericPageBlockItem
          toolbarShare
          toolbarExpand
          loading={locationsLoading}
          title={`${props.period} Countries`}
          onExpand={() => handleSetExpandedBlock("key-locations")}
          description={
            <React.Fragment>
              {locationsData.stats
                .slice(0, locationsData.stats.length - 1)
                .map((i) => (
                  <React.Fragment key={i.code}>
                    <span
                      style={{
                        color: colors.primary.blue,
                      }}
                    >
                      {i.name}
                    </span>
                    {", "}
                  </React.Fragment>
                ))}
              and{" "}
              <React.Fragment>
                <span
                  style={{
                    color: colors.primary.blue,
                  }}
                >
                  {locationsData.stats[locationsData.stats.length - 1]?.name}
                </span>{" "}
              </React.Fragment>
              are the largest recipient countries of {props.period}.
            </React.Fragment>
          }
          content={
            <LocationsBlock
              items={items}
              transactionType={props.transactionType.label}
            />
          }
          expanded={expandedBlock === "key-locations"}
        />
        {expandedBlock === "key-locations" && (
          <Box
            height="650px"
            padding="0 30px 20px 30px"
            bgcolor={colors.primary.white}
          >
            <Divider />
            <Box height={50} />
            <GeomapChart
              data={locationsData.geo_data}
              showLegend
              showTooltip
              height="500px"
              variant="explorer"
              showZoomWidget
            />
          </Box>
        )}
      </React.Fragment>
    );
  }, [
    expandedBlock,
    locationsData,
    locationsLoading,
    props.period,
    props.transactionType,
  ]);

  const organisationsData: {
    id: string;
    name: string;
    count: number;
    value: number;
  }[] = useStoreState((state) =>
    get(state.ExplorerProjectionsOrganisationsDonut.data, "data", [])
  );
  const organisationsLoading = useStoreState(
    (state) => state.ExplorerProjectionsOrganisationsDonut.loading
  );
  const organisations = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        height="100%"
        toolbarExpand
        loading={organisationsLoading}
        title={`${props.period} Organisations`}
        onExpand={() => handleSetExpandedBlock("key-organisations")}
        description={
          <React.Fragment>
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {organisationsData[0]?.name}
            </span>{" "}
            will be one of the important organisations shaping our future in{" "}
            {props.period}.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize="12px" lineHeight="normal">
              Organisations engaged in activities spanning in {props.period}:
            </Typography>
            <Box height={20} />
            <PieChart
              id="key-projections-organisations"
              unitType="organisations"
              data={organisationsData}
              period={props.period}
              unit="USD"
              variant="explorer"
              height={expandedBlock === "key-organisations" ? "700px" : "333px"}
              showTooltip={expandedBlock === "key-organisations" ? true : false}
              showLabel={expandedBlock === "key-organisations" ? false : true}
            />
          </React.Fragment>
        }
        expanded={expandedBlock === "key-organisations"}
      />
    );
  }, [expandedBlock, organisationsData, organisationsLoading, props.period]);

  const sectorsData: {
    code: string;
    name: string;
    value: number;
    prevValue: number;
    changePercentage: number;
  }[] = useStoreState((state) =>
    get(state.ExplorerProjectionsSectors.data, "data", [])
  );
  const sectorsLoading = useStoreState(
    (state) => state.ExplorerProjectionsSectors.loading
  );
  const sectors = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        height="100%"
        toolbarExpand
        loading={sectorsLoading}
        title={`${props.period} Sectors`}
        onExpand={() => handleSetExpandedBlock("key-sectors")}
        description={
          <React.Fragment>
            The{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              {sectorsData[0]?.name}
            </span>{" "}
            sector is anticipated to secure the most substantial funding in{" "}
            {props.period}.
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Typography variant="body2" fontSize="12px" lineHeight="normal">
              Here's a curated collection of the visionary activities planned to
              extend their impact in {props.period}
            </Typography>
            <Box height={20} />
            <PieChart
              id="key-projections-sectors"
              half
              donut
              data={sectorsData.map((i) => ({
                id: i.code,
                name: i.name,
                value: i.value,
              }))}
              unit="USD"
              unitType="sectors"
              period={props.period}
              height={expandedBlock === "key-sectors" ? "500px" : "424px"}
            />
          </React.Fragment>
        }
        expanded={expandedBlock === "key-sectors"}
      />
    );
  }, [expandedBlock, sectorsData, sectorsLoading, props.period]);

  const targets = React.useMemo(() => {
    return (
      <GenericPageBlockItem
        toolbarShare
        toolbarExpand
        title="2050 Targets"
        onExpand={() => handleSetExpandedBlock("key-targets")}
        description={
          <React.Fragment>
            Total of{" "}
            <span
              style={{
                color: colors.primary.blue,
              }}
            >
              1,457 activities
            </span>{" "}
            have been meticulously planned to extend their efforts well into the
            future, all with a resolute commitment to shaping a brighter world
            by the year 2050.
          </React.Fragment>
        }
        content={
          expandedBlock === "key-targets" ? (
            <Box>
              <Table
                withSearch
                rows={IMPACT_CHART_ITEMS_EXTRA}
                columns={TARGET_COLUMNS}
                headerFontSize="12px"
              />
            </Box>
          ) : (
            <ImpactChart items={[...IMPACT_CHART_ITEMS]} variant="target" />
          )
        }
        expanded={expandedBlock === "key-targets"}
      />
    );
  }, [expandedBlock]);

  const expandedBlockView = React.useMemo(() => {
    let view = <React.Fragment />;
    switch (expandedBlock) {
      case "key-locations":
        view = locations;
        break;
      case "key-organisations":
        view = organisations;
        break;
      case "key-sectors":
        view = sectors;
        break;
      case "key-targets":
        view = targets;
        break;
      default:
        break;
    }

    return (
      <React.Fragment>
        <Box height={40} />
        {view}
      </React.Fragment>
    );
  }, [expandedBlock, locations, organisations, sectors, targets]);

  const getBlockHeight = React.useCallback((blockId: string) => {
    const el = document.getElementById(blockId);
    return el ? `${el.offsetHeight}px` : "100%";
  }, []);

  const locationsBlockHeight = React.useMemo(
    () => getBlockHeight("block-2050 Countries"),
    [locations]
  );
  const organisationsBlockHeight = React.useMemo(
    () => getBlockHeight("block-2050 Organisations"),
    [organisations]
  );
  const sectorsBlockHeight = React.useMemo(
    () => getBlockHeight("block-2050 Sectors"),
    [sectors]
  );
  const targetsBlockHeight = React.useMemo(
    () => getBlockHeight("block-2050 Targets"),
    [targets]
  );

  return (
    <Grid container spacing={4}>
      {expandedBlock && (
        <Grid item xs={12}>
          <ScrollIntoView trigger={expandedBlock} />
          {expandedBlockView}
        </Grid>
      )}
      <Grid item xs={12} md={7}>
        {overview}
        <Box sx={{ height: 20 }} />
        {expandedBlock !== "key-locations" ? (
          locations
        ) : (
          <ChartPlaceholder height={locationsBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} md={5}>
        {expandedBlock !== "key-organisations" ? (
          organisations
        ) : (
          <ChartPlaceholder height={organisationsBlockHeight} />
        )}
      </Grid>

      <Grid item xs={12} md={7}>
        {expandedBlock !== "key-sectors" ? (
          sectors
        ) : (
          <ChartPlaceholder height={sectorsBlockHeight} />
        )}
      </Grid>
      <Grid item xs={12} md={5}>
        {expandedBlock !== "key-targets" ? (
          targets
        ) : (
          <ChartPlaceholder height={targetsBlockHeight} />
        )}
      </Grid>
    </Grid>
  );
};
