import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { ArrowDown } from "app/assets/vectors/jsx/arrow";
import { DateRangePicker } from "app/components/date-range-picker";
import {
  ButtonStyle,
  PeriodSelectButtonProps,
} from "app/components/period-select-button/data";

export const PeriodSelectButton = (props: PeriodSelectButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStartDateChange = (date: Date) => {
    props.setData({ start: date, end: props.data.end });
  };

  const handleEndDateChange = (date: Date) => {
    props.setData({ start: props.data.start, end: date });
  };

  const buttonLabel = React.useMemo(() => {
    const startDate = props.data.start.getDate();
    const endDate = props.data.end.getDate();
    const startYear = props.data.start.getFullYear();
    const endYear = props.data.end.getFullYear();
    const startMonth = props.data.start.getMonth() + 1;
    const endMonth = props.data.end.getMonth() + 1;
    if (startMonth === 1 && endMonth === 12) {
      if (startYear === endYear) return startYear.toString();
      return `${startYear} - ${endYear}`;
    }
    return `${startDate < 10 ? "0" : ""}${startDate}-${
      startMonth < 10 ? "0" : ""
    }${startMonth}-${startYear} - ${endDate < 10 ? "0" : ""}${endDate}-${
      endMonth < 10 ? "0" : ""
    }${endMonth}-${endYear}`;
  }, [props.data]);

  const open = Boolean(anchorEl);
  const id = open ? "year-select-popup" : undefined;

  return (
    <Box>
      <Button
        aria-describedby={id}
        variant="general"
        sx={ButtonStyle}
        onClick={handleOpen}
      >
        {buttonLabel}
        <ArrowDown transform={`rotate(${open ? "180" : "0"})`} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: "center",
        }}
        sx={{
          zIndex: 3,
          ".MuiPopover-paper": {
            background: "transparent",
          },
        }}
      >
        <DateRangePicker
          startDate={props.data.start}
          endDate={props.data.end}
          setStartDate={handleStartDateChange}
          setEndDate={handleEndDateChange}
        />
      </Popover>
    </Box>
  );
};
