import axios from 'axios';
import React from 'react';

export const useSubmitContactForm = ({
  onSuccess,
}: {
  onSuccess?: () => void;
} = {}) => {
  const [message, setMessage] = React.useState('');

  const [loading, setLoading] = React.useState(false);

  const submit = async (data: {
    email: string;
    firstName: string;
    lastName: string;
    company?: string;
    message: string;
  }) => {
    setLoading(true);
    try {
      // Simulate a network request
      const response = await axios.post(
        `${process.env.REACT_APP_API}/send-contact-form-to-intercom`,
        data
      );
      setMessage(response.data.message ?? 'Error in submitting form');
      if (!response.data.error) {
        onSuccess?.();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return { message, loading, submit };
};
