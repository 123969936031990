import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { headerButtonsItems } from "app/components/header/sub-components/buttons/data";
import { HeaderPlansView } from "app/components/header/sub-components/expanded-view/views/plans";
import { HeaderExpandedViewProps } from "app/components/header/sub-components/expanded-view/data";
import { HeaderSignInView } from "app/components/header/sub-components/expanded-view/views/signin";
import { HeaderProductView } from "app/components/header/sub-components/expanded-view/views/product";
import { HeaderResourcesView } from "app/components/header/sub-components/expanded-view/views/resources";

export const HeaderExpandedView: React.FC<HeaderExpandedViewProps> = (
  props: HeaderExpandedViewProps
) => {
  const view = React.useMemo(() => {
    switch (props.activeButton) {
      case headerButtonsItems[0].label:
        return <HeaderProductView />;
      case headerButtonsItems[1].label:
        return <HeaderPlansView />;
      case headerButtonsItems[2].label:
        return <HeaderResourcesView />;
      case headerButtonsItems[3].label:
        return <HeaderSignInView />;
      default:
        return null;
    }
  }, [props.activeButton]);

  return (
    <Box
      sx={{
        left: 0,
        width: "100vw",
        position: "absolute",
        background: colors.secondary.lightGrey,
        borderBottom: `1px solid ${colors.secondary.iconGray}`,
      }}
    >
      <Container maxWidth="lg" disableGutters>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "20px 0 30px 0",
            background: colors.secondary.lightGrey,
          }}
        >
          {view}
        </Box>
      </Container>
    </Box>
  );
};
