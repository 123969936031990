export interface PeriodSelectButtonProps {
  data: { start: Date; end: Date };
  setData: (data: { start: Date; end: Date }) => void;
}

export const ButtonStyle = {
  display: "flex",
  columnGap: "5px",
  padding: "8px 10px",
  alignItems: "center",
};
