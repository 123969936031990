import { Box, Typography } from "@mui/material";
import { CheckIcon } from "app/assets/vectors/jsx/CheckIcon";
import { colors } from "app/theme";
import React from "react";
import { FeatureLine } from "../line";

export const Features = () => {
  const features = [
    {
      title: "Top Features",
      content: [
        {
          title: "",
          options: [
            {
              title: "Validation based on latest version of IATI Standard",
              values: [true, true, true],
            },
            {
              title: "Automated quality check to ensure technical valid XML",
              values: [false, "100 Files", "1000 Files"],
            },
            {
              title: "Direct visualisation of your data",
              values: [true, false, true],
            },
            {
              title: "Scheduling to automate data publishing",
              values: [false, false, true],
            },
            {
              title: "Data alerts for changes in your bookmarked IATI data",
              values: [false, false, true],
            },
            {
              title:
                "Reversed publishing from XML to sheets for easy data management (XML 2 Spreadsheet)",
              values: [false, true, true],
            },
          ],
        },
      ],
    },
    {
      title: "Plan Details",
      content: [
        {
          title: "AIDA Publisher",
          options: [
            {
              title: "Spreadsheet templates for easy IATI reporting",
              values: [true, true, true],
            },
            {
              title: "Automated conversion from XML for IATI compliant format",
              values: [false, true, true],
            },
            {
              title: "Possibility to publish in all fields of IATI",
              values: [true, false, true],
            },
            {
              title:
                "Direct publication XML file for the IATI Registry (AIDA Cloud based storage)",
              values: [false, false, true],
            },
            {
              title: "Google Drive Connection (Cloud)",
              values: [false, false, true],
            },
            {
              title: "Scheduling to automate data publishing",
              values: [false, true, true],
            },
            {
              title: "Preview data prior to publishing (XML previewer)",
              values: [false, true, true],
            },
            {
              title:
                "Reversed publishing from XML to sheets for easy data management (XML 2 Spreadsheet)",
              values: [false, true, true],
            },
            {
              title: "Validation based on latest version of IATI Standard",
              values: [false, true, true],
            },
            {
              title:
                "XML line viewer to directly spot critical errors in your publication",
              values: [false, true, true],
            },
            {
              title: "Semi-Automated Quality Report on potential Enhancement",
              values: [false, true, true],
            },
            {
              title: "Preview data prior to publishing (XML previewer)",
              values: [false, true, true],
            },
            {
              title: "Direct visualisation of your uploaded data",
              values: [false, true, true],
            },
          ],
        },
        {
          title: "AIDA Explorer",
          options: [
            {
              title: "Visualised IATI data",
              values: [true, true, true],
            },
            {
              title: "Data analysis and curation",
              values: [true, true, true],
            },
            {
              title: "Access to live data",
              values: [true, true, true],
            },

            {
              title: "Future projections of data",
              values: [true, true, true],
            },

            {
              title: "Add your selection of IATI data to your library",
              values: [true, true, true],
            },

            {
              title: "Detailed filtering options",
              values: [true, true, true],
            },

            {
              title: "Location specific data",
              values: [true, true, true],
            },

            {
              title: "Organisation specific data",
              values: [true, true, true],
            },

            {
              title: "Sector specific data",
              values: [true, true, true],
            },

            {
              title: "Sharable and downloadable data",
              values: [true, true, true],
            },
          ],
        },
        {
          title: "User Management",
          options: [
            {
              title: "User account",
              values: [false, true, true],
            },
            {
              title: "Team management",
              values: [false, true, true],
            },
          ],
        },
        {
          title: "Support Package",
          options: [
            {
              title: "Set up your account",
              values: [false, true, true],
            },
            {
              title: "Set up your IATI Publication",
              values: [false, true, true],
            },
            {
              title: "Helpdesk access",
              values: [false, true, true],
            },
            {
              title: "Data Mapping",
              values: [false, false, true],
            },
            {
              title: "Personalised guidance",
              values: [false, false, true],
            },
          ],
        },
      ],
    },
  ];
  return (
    <Box>
      {features.map((feature) => (
        <Box key={feature.title}>
          <Typography
            variant="h3"
            fontSize={"24px"}
            lineHeight={"normal"}
            padding={"10px 0"}
            margin={"10px 0"}
          >
            {feature.title}
          </Typography>

          {feature.content.map((content) => (
            <Box key={content.title}>
              {content.title ? (
                <Typography
                  variant="h4"
                  fontSize={"18px"}
                  lineHeight={"normal"}
                  padding={"5px 0"}
                  margin={"10px 0"}
                >
                  {content.title}
                </Typography>
              ) : null}
              <Box sx={{ borderLeft: `3px solid ${colors.primary.blue}` }}>
                {content.options.map((option, index) => (
                  <FeatureLine key={index} option={option} index={index} />
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};
