import React from "react";
import Box from "@mui/material/Box";

export const ScrollIntoView = ({
  children,
  trigger,
}: {
  children?: React.ReactNode;
  trigger: any;
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (trigger && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [trigger]);

  return <Box ref={ref}>{children}</Box>;
};
