import get from "lodash/get";
import find from "lodash/find";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { GridColDef } from "@mui/x-data-grid";
import { CircleFlag } from "react-circle-flags";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { formatFinancialValue } from "app/utils/formatFinancialValue";
import { ACTIVITY_STATUS_COLORS } from "app/pages/explorer/components/search/tableColumns";
import {
  FavouriteIcon,
  FavouriteIconSelected,
} from "app/assets/vectors/jsx/FavouriteIcon";

export const ACTIVITIES_COLUMNS: GridColDef[] = [
  {
    field: "favourite",
    headerName: "Favourite",
    width: 76,
    align: "center",
  },
  {
    field: "title",
    headerName: "Activity Title",
    width: 500,
    flex: 1,
  },
  {
    field: "locations",
    headerName: "Location(s)",
    width: 150,
    renderCell: (params: any) => {
      if (params.value.length === 0) {
        return null;
      }
      const multi = params.value.length > 1;
      let name = params.value[0].name;
      let code = params.value[0].code.toLowerCase();
      if (multi) {
        name = "Multiple countries";
        code = "";
      }
      return (
        <Tooltip
          title={
            multi ? (
              <Box
                gap="5px"
                display="flex"
                maxHeight="250px"
                className="scroller"
                flexDirection="column"
                sx={{
                  overflowY: "auto",
                }}
              >
                {params.value.map((location: any) => (
                  <Grid
                    key={location.code}
                    display={"flex"}
                    alignItems={"center"}
                    columnGap={"5px"}
                  >
                    <CircleFlag
                      countryCode={location.code.toLowerCase()}
                      height="12"
                      width="12"
                    />
                    <Typography
                      variant="subtitle1"
                      fontSize={"12px"}
                      fontWeight={400}
                    >
                      {location.name}
                    </Typography>
                  </Grid>
                ))}
              </Box>
            ) : (
              ""
            )
          }
        >
          <Grid
            display={"flex"}
            alignItems={"center"}
            columnGap={"5px"}
            height={"100%"}
          >
            <CircleFlag countryCode={code} height="12" width="12" />
            <Typography variant="subtitle1" fontSize={"12px"} fontWeight={400}>
              {name}
            </Typography>
          </Grid>
        </Tooltip>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    renderCell: (params: any) => (
      <Grid
        display={"flex"}
        alignItems={"center"}
        columnGap={"6px"}
        height={"100%"}
      >
        <Box
          sx={{
            width: "9px",
            height: "9px",
            borderRadius: "50%",
            flexShrink: 0,
            background: get(ACTIVITY_STATUS_COLORS, params.value, "#333ED4"),
          }}
        />
        <Typography variant="subtitle1" fontSize={"12px"} fontWeight={400}>
          {params.value}
        </Typography>
      </Grid>
    ),
  },
  {
    field: "commitment",
    headerName: "Total Commitment",
    width: 150,
    renderCell: (params: any) => (
      <Grid
        rowGap="2px"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Typography variant="subtitle1" fontSize="12px" fontWeight={400}>
          {formatFinancialValue(params.value)}
        </Typography>
        <Box
          sx={{
            width: "100%",
            height: "5px",
            background: colors.secondary.disableText,
          }}
        >
          <Box
            sx={{
              height: "100%",
              background: colors.primary.blue,
              width: `${
                params.row.commitmentPercentage > 100
                  ? 100
                  : params.row.commitmentPercentage
              }%`,
            }}
          />
        </Box>
      </Grid>
    ),
  },
];

export const getActivitiesColumns = (
  favourites: { id: string }[],
  onFavouriteClick: (values: {
    name: string;
    path: string;
    itemId: string;
  }) => void
): GridColDef[] => {
  const columns = [...ACTIVITIES_COLUMNS];
  ACTIVITIES_COLUMNS[0].renderCell = (params: any) => {
    const isFavourite = find(favourites, { itemId: params.row.id });

    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onFavouriteClick({
        itemId: params.row.id,
        name: params.row.title,
        path: `/activities/${params.row.id}`,
      });
    };

    return (
      <IconButton onClick={onClick}>
        {isFavourite ? <FavouriteIconSelected /> : <FavouriteIcon />}
      </IconButton>
    );
  };
  return columns;
};
