export interface HeaderButtonProps {
  label: string;
  link?: string;
  signIn?: boolean;
  expandable?: boolean;
}

export interface HeaderButtonsProps {
  activeButton: string;
  buttons: HeaderButtonProps[];
  handleButtonClick: (label: string) => void;
}

export const headerButtonsItems: HeaderButtonProps[] = [
  {
    label: "Product",
    expandable: true,
  },
  {
    label: "Plans",
    expandable: true,
  },
  {
    label: "Resources",
    expandable: true,
  },
  {
    label: "Sign In",
    expandable: true,
    signIn: true,
  },
];
